import ReactGAN from "react-ga4";



export function gaRecommendationSkipped( ) : void {

  var d = Date.now();

  ReactGAN.event({
    action: "Recommendation process Skipped",
    category: "User Details",
    label : 'user has skipped the recommendation process',
    value : d,

  });

}


export function gaRecommendationSubmitted(score : number , email : string ) : void {

  var d = Date.now();

  ReactGAN.event({
    action: "Recommendation score",
    category: "User Details",
    label : score.toString(),
    value : score,

  });

  // ReactGAN.event({
  //   action: "Recommendation email",
  //   category: "User Details",
  //   label : email,
  //   value : 1,

  // });
  
  
  ReactGAN.event({
    action: "Recommendation date",
    category: "User Details",
    label : Date.now.toString(),

  });



}




export function gaPersonalInfoSkipped( ) : void {

  var d = Date.now();

  ReactGAN.event({
    action: "Anonymous Info Skipped",
    category: "User Details",
    label : 'user has skipped',
    value : d,

  });

}


export function gaPersonalInfoSubmitted(gender : string, year: number, status : string, military : string, servicetype : string ) : void {
  
  var d = Date.now();
  
  ReactGAN.event({
    action: "Gender Submitted",
    category: "User Details",
    label : gender,
    value : d,

  });
  
    ReactGAN.event({
    action: "Birth Year Submitted",
    category: "User Details",
    label : year.toString(),
    value : d,

  });
  
    ReactGAN.event({
    action: "Status Submitted",
    category: "User Details",
    label : status,
    value : d,

  });
  
    ReactGAN.event({
    action: "Military Background Submitted",
    category: "User Details",
    label : military,
    value : d,

  });  
  
  ReactGAN.event({
    action: "Service Type Submitted",
    category: "User Details",
    label : servicetype,
    value : d,

  });
  
  console.log(gender,year,status,military,servicetype,d);
}


export function gaPersonalInfoResubmitted(info :string, thevalue :string) : void {
  
  if (info == "gender" )
  {
    ReactGAN.event({
      action: "Gender changed",
      category: "User Details",
      label : thevalue,
    });
  }

  if (info == "birth_year" )
  {
    ReactGAN.event({
      action: "Birth Year changed",
      category: "User Details",
      label : thevalue,
    });
  }

  if (info == "military" )
  {
    ReactGAN.event({
      action: "Military Background changed",
      category: "User Details",
      label : thevalue,
    });
  }

  if (info == "status" )
  {
    ReactGAN.event({
      action: "Status changed",
      category: "User Details",
      label : thevalue,
    });
  }

  if (info == "service" )
  {
    ReactGAN.event({
      action: "Service Type changed",
      category: "User Details",
      label : thevalue,
    });
  }
console.log(info,thevalue);
  
}



export function gaFilterDistanceBarChange(FilterValue : number) : void {
  ReactGAN.event({
    action: "Filter Distance Bar Changed ",
    category: "UI",
    label : FilterValue.toString(),
    value : FilterValue,
  });
}

export function gaGeolocate(Distance: number, Time : number ) : void {
    //write two events
    
  ReactGAN.event({
    action: "Route Selected",
    category: "Geolocation",
    label: "Geolocation Route"
  });
  
  ReactGAN.event({
    action: "Travel Distance Kilometers",
    category: "Geolocation",
    label: "Total Distance Kilometers",
    value : Distance * 1.609,
  });
  
  ReactGAN.event({
    action: "Travel Time in Hours",
    category: "Geolocation",
    label: "Total Time hours",
    value : Time/60,
  });
  
}

export function gaFeedbackSubmitted(rating : number, commentlength : number, IsServiceProvider : boolean ) : void {
  
  var d = Date.now();
  
  ReactGAN.event({
    action: "Feedback Rating Submitted",
    category: "Feedback",
    label : d.toString(),
    value : rating,
  });
  
  if (commentlength > 0)
  {
    ReactGAN.event({
      action: "Feedback Comment Submitted",
      category: "Feedback",
      label : d.toString(),
      value : commentlength,
    });
  }
  
  if (IsServiceProvider == true)
  {
    ReactGAN.event({
      action: "Feedback left by Provider",
      category: "Feedback",
      label : d.toString(),
    });
  }
  else
  {
    ReactGAN.event({
      action: "Feedback left by Veteran",
      category: "Feedback",
      label : d.toString(),
    });
    
  }
  
  
}


export function gaWebsiteClicked(viaButton : boolean, orgname: string) : void {
  
  if (viaButton == true)
  {
    ReactGAN.event({
      action: "Clicked on Website ",
      category: "UI",
      label : orgname,
    });
  }
  else
   {
    ReactGAN.event({
      action: "Clicked on Website via Popup Link ",
      category: "UI",
      label : orgname,
    });
  }
 
    
}


export function gaAboutMeClicked() : void {
  ReactGAN.event({
    action: "About Me Clicked ",
    category: "UI",
  });
}



export function gaFavouritesClicked(orgname : string, IsFavourite : boolean) : void {
  
  if (IsFavourite == true)
  {
    ReactGAN.event({
      action: "Organisation Favourited ",
      category: "UI",
      label : orgname,
    });
  }
  else
  {
        ReactGAN.event({
      action: "Organisation Removed from Favourites ",
      category: "UI",
      label : orgname,
    });
    
  }
}
export function gaFeedbackClicked() : void {
  ReactGAN.event({
    action: "About Me Clicked ",
    category: "UI",
  });
}




export function gaSearchUsed(SearchText : string) : void {
  ReactGAN.event({
    action: "Clicked on Provider ",
    category: "Provider",
  });
}

export function gaProviderInfo(Orgname: string) : void {
  ReactGAN.event({
    action: "Provider" ,
    category: "Provider Selected",
    label : Orgname,
    
  });
}


export function gaCategoryInfo(category: string): void {
    console.log("Category Analytics ")
  ReactGAN.event({
    action: "Category ",
    category: "Category Selected",
    label : category
  });
}




export function gaFavouritesCategoryClicked() : void {
  ReactGAN.event({
    action: "About Me Clicked ",
    category: "UI",
  });
}



export function gaMobileFilter(bOnline : boolean) : void {
  
  if (bOnline)
  {
  ReactGAN.event({
    action: "Online Filter On",
    category: "UI Mobile",
    label : "Online Filter On for Mobile "
  });
  }
  else
  {
  ReactGAN.event({
    action: "In Person Filter On",
    category: "UI Mobile",
    label : "In Person Filter On for Mobile "
  });
    
  }
}



export function gaOnlineFilterClicked(bTurnedOn : boolean, bInperonOn : boolean) : void {
  
  if (!bTurnedOn)
  {
    if (bInperonOn)
    {
      ReactGAN.event({
      action: "Online Filter Turned On",
      category: "UI",
      label : "In Person Filter is On"
      });
  ///////////////////////////    console.log("Online ON Inperson on") ;
    }
    else
    {
      ReactGAN.event({
      action: "Online Filter Turned On",
      category: "UI",
      label : "In Person Filter off"
      });
            //console.log("Online ON Inperson off") ;
    }
    
  } 
  else
    if (bInperonOn)
    {
      ReactGAN.event({
      action: "Online Filter Turned Off",
      category: "UI",
      label : "In Person Filter is On"
      });
        //    console.log("Online Off Inperson on") ;
    }
    else
    {
      ReactGAN.event({
      action: "Online Filter Turned Off",
      category: "UI",
      label : "In Person Filter off"
      });
      //            console.log("Online Off Inperson off") ;
    }
  
  
}
export function gaInPersonFilterClicked(bTurnedOn : boolean, bOnline : boolean) : void {
  
  if (!bTurnedOn)
  {
    if (bOnline)
    {
      ReactGAN.event({
      action: "In Person Filter Turned On",
      category: "UI",
      label : "Online Filter is On"
      });
    //    console.log("Inperson ON Online on") ;
    }
    else
    {
      ReactGAN.event({
      action: "In Person Filter Turned On",
      category: "UI",
      label : "Online Filter off"
      });
              //    console.log("Inperson ON Online off") ;
    }
    
  } 
  else
    if (bOnline)
    {
      ReactGAN.event({
      action: "In Person Filter Turned Off",
      category: "UI",
      label : "Online Filter is On"
      });
                  //console.log("Inperson Off Online on") ;
    }
    else
    {
      ReactGAN.event({
      action: "In Person Filter Turned Off",
      category: "UI",
      label : "Online Filter off"
      });
                //        console.log("Inperson Off Online off") ;
    }

}

export function gaRecentlyVisitedFilterClicked(bTurnedOn : boolean, bFavourites : boolean) : void {
  
  if (!bTurnedOn)
  {
    if (bFavourites)
    {
      ReactGAN.event({
      action: "Recently Visited Filter Turned On",
      category: "UI",
      label : "Favourites Filter is On"
      });
  //      console.log("Recently ON Favourites on") ;
    }
    else
    {
      ReactGAN.event({
      action: "Recently Visited Filter Turned On",
      category: "UI",
      label : "Favourites Filter off"
      });
  //                console.log("Recently ON Favourites off") ;
    }
    
  } 
  else
    if (bFavourites)
    {
      ReactGAN.event({
      action: "Recently Visited Filter Turned Off",
      category: "UI",
      label : "Favourites Filter is On"
      });
  //                console.log("Recently Off Favourites on") ;
    }
    else
    {
      ReactGAN.event({
      action: "Recently Visited Filter Turned Off",
      category: "UI",
      label : "Favourites Filter off"
      });
  //                      console.log("Recently Off Favourites off") ;
    }

}


export function gaFavouriteFilterClicked(bTurnedOn : boolean, bRecent : boolean) : void {
  
  if (!bTurnedOn)
  {
    if (bRecent)
    {
      ReactGAN.event({
      action: "Favourites Filter Turned On",
      category: "UI",
      label : "Recently Visited Filter is On"
      });
 //       console.log("Favourites ON Recently on") ;
    }
    else
    {
      ReactGAN.event({
      action: "Favourites Filter Turned On",
      category: "UI",
      label : "Recently Visited Filter off"
      });
  //                console.log("Favourites ON Recently off") ;
    }
    
  } 
  else
    if (bRecent)
    {
      ReactGAN.event({
      action: "Favourites Filter Turned Off",
      category: "UI",
      label : "Recently Visited Filter is On"
      });
  //                console.log("Favourites Off Recently on") ;
    }
    else
    {
      ReactGAN.event({
      action: "Favourites Filter Turned Off",
      category: "UI",
      label : "Recently Visited Filter off"
      });
  //                      console.log("Favourites Off Recently off") ;
    }

}

