import React, { useState, useEffect, useRef } from "react";
import { loadModules } from "esri-loader";
import { ServiceComponent } from "./Services.component";
//import { FilterBar } from "./FilterBar.component";
import { SearchModal } from "./SearchModal.component";
//import { AboutMe } from "./AboutMe.component";
import { onItemSelect } from "../actions/searchActions";
import { useIsMount } from "../actions/generalHelper";
import {
  getDirections,
  loadDirectionLayer,
  loadFeatureLayer,
  loadMap,
  loadMapSearch,
  getGeoResults,
  filterLayerView,
  getMapZoom
} from "../actions/mapHelper";
import {
  getOnlineResults,
  getOnlineData,
} from "../actions/dataHelper";

import {gaFilterDistanceBarChange} from "../actions/gaevents";

let directionsLayer: any;
let mapView: any;
let servufeatureLayer: any;
let featureLayerView: any;
let search: any;
let csvResult: any[] = [];
let tracker: any;
let arcGISMap: any;
let highlighted: any;
let featurePopup: any;
let distanceSlider: any;

export const MapComponent = () => {
  const [selectedCategory, setSelectedCategory] = useState<
    string | undefined
  >();
  const [onlineResults, setOnlineResults] = useState<any[]>([]);
  const [geoResults, setGeoResults] = useState<any[]>([]);
  const [distanceFilter, setDistanceFilter] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  const mapRef = useRef(null);
  const isMount = useIsMount();
  const [selectedItem, setSelectedItem] = useState();
  const [track, setTrack] = useState(false);
  const [mapUpdates, setMapUpdates] = useState(0);
  const [hoverItem, setHoverItem] = useState();
  const [myLocation, setMyLocation] = useState<any>();
  const [militaryStatus, setMilitaryStatus] = useState<string | undefined>();

  useEffect(() => {
    initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (distanceSlider) distanceSlider.values = [distanceFilter];
  }, [distanceFilter]);

  const initialLoad = async () => {
    ({ arcGISMap, mapView } = await loadMap(mapRef));
    ({ servufeatureLayer } = await loadFeatureLayer({
      arcGISMap,
      mapView,
      setMapUpdates,
      setFeatureLayerView: (view: any) => featureLayerView = view
    }));
    ({ directionsLayer, tracker } = await loadDirectionLayer(
      arcGISMap,
      mapView
    ));
    search = await loadMapSearch({ mapView, setMyLocation, setTrack });
    await loadDistanceFilter();
    await loadOnlineData();
  };

  const loadDistanceFilter = async () => {
    const [Slider] = await loadModules(["esri/widgets/Slider"], {
      css: true,
    });
    distanceSlider = new Slider({
      container: "buffer-slider",
      min: 0,
      max: 100,
      steps: 10,
      visibleElements: {
        labels: true,
      },
      precision: 0,
      labelFormatFunction: function (value: { toString: () => string }) {
        return `${value.toString()} km`;
      },
      values: [distanceFilter]
    });

    distanceSlider.on(["thumb-change", "thumb-drag"], bufferVariablesChanged);

    function bufferVariablesChanged(event: { value: number }) {
      gaFilterDistanceBarChange(event.value);    
      setDistanceFilter(event.value);
    }
  };

  const loadOnlineData = async () => {
    csvResult = await getOnlineData();
  };


  useEffect(() => {
    if (!isMount) {
      if (track) {
        tracker.start();
      } else tracker.stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track]);

  useEffect(() => {
    if (!isMount) {
      const highlightQuery = servufeatureLayer.createQuery();
      highlightQuery.where = `PID='${hoverItem.PID}'`;
      highlightQuery.outFields = ["*"];
      servufeatureLayer
        .queryFeatures(highlightQuery)
        .then(function (result: any) {
          if (highlighted) {
            highlighted.remove();
          }
          highlighted = featureLayerView.highlight(result.features[0]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoverItem]);

  const searchTerm = async (term: string) => {
    await search.search(term);
    if (selectedItem)
      getDirections({
        to: selectedItem.geometry,
        directionsLayer,
        myLocation,
        search,
        mapView,
        featurePopup,
        setOpenSearch,
      });
    onFilterLayerView();
  };

  //for testing popup data and zoom in
  const getPopupData = (selectedItem: any) => {
    featurePopup = mapView.popup;
    
  let ph = selectedItem.Phone;
  let py = selectedItem.PhonePretty;
  let phoner = "";
 if (selectedItem.PhonePretty == "No Number Provided")
 {
  phoner = '<p>Phone: No Number Provided </p>' +   
   "Specialises in:" +
            "<br/><br/>" +
            selectedItem.Specialises;
 }
 else
 {
 phoner =     "<br/><br/>" +
          '<p>Phone: <a href="tel:'+ ph + '">' +  py +  '</a> </p>' +
            "Specialises in:" +
            "<br/><br/>" +
            selectedItem.Specialises;
 //let samp = '<a href=“tel:0419223223”>mynum</a>';
 }  
     
    mapView.when(function () {
      featurePopup.open({
        title: selectedItem.OrgName,
        content:
          selectedItem.Address +
          "<br/><br/>" +
          `<a target="_blank" href="${!selectedItem.Website.startsWith("http") ? "https://" : ""
         }${selectedItem.Website}">${selectedItem.Website}</a>`
          + 
           phoner,
          location: selectedItem.geometry,
        dockEnabled: true,
      });
      //zoom in
      mapView
        .goTo({
          center: selectedItem.geometry,
          zoom: 15,
        })
        .catch(function (error: any) {
          if (error.name !== "AbortError") {
            console.error(error);
          }
        });
    });
  };

  const filterOnlineResults = async () => {
    let onlineResults = await getOnlineResults({ selectedCategory, csvResult, militaryStatus });
    setOnlineResults(onlineResults);
  };

  useEffect(() => {
    filterGeoResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapUpdates]);

  //testing cookie results
  // useEffect(() => {
  //   filterGeoResults();
  //   filterOnlineResults();
  //   onFilterLayerView();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [militaryStatus]);

  const filterGeoResults = async () => {
    if (!selectedItem && selectedCategory) {
      const geoResults = await getGeoResults({
        featureLayerView,
        servufeatureLayer,
        myLocation,
      });
      setGeoResults(geoResults);
    }
  };

  const onFilterLayerView = (isCategoryChange: boolean = false, isMobile: boolean = false) => {
    filterLayerView({
      isCategoryChange,
      isMobile,
      selectedItem,
      featureLayerView,
      getPopupData,
      onItemSelect,
      selectedCategory,
      directionsLayer,
      search,
      setTrack,
      myLocation,
      distanceFilter,
      militaryStatus
    });
  };

  useEffect(() => {
    filterGeoResults();
    filterOnlineResults();
    onFilterLayerView(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    onFilterLayerView();
    if (distanceFilter !== 0 && !myLocation) {
      setOpenSearch(true)
    } else if (mapView) {
      onFilterLayerView();
      mapView.goTo({
        center: myLocation,
        zoom: getMapZoom(distanceFilter),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distanceFilter]);

  useEffect(() => {
    onFilterLayerView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <div>
      <div className="map-options">
        
      </div>
      <div className="map-wrapper">
        <div className="map-categories">
          <div className="map-categories-inner">
            <div className="map-category-options">
              <ServiceComponent
                getDirections={(selectedItem: any) =>
                  getDirections({
                    to: selectedItem.geometry,
                    directionsLayer,
                    myLocation,
                    search,
                    mapView,
                    featurePopup,
                    setOpenSearch,
                  })
                }
                selectedCategory={selectedCategory}
                setSelectedCategory={(val: any) => {
                  if (featureLayerView) setSelectedCategory(val);
                }}
                onlineResults={onlineResults}
                geoResults={geoResults}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
                setHoverItem={setHoverItem}
                searchTerm={searchTerm}
                servufeatureLayer={servufeatureLayer}
                csvResult={csvResult}
                mapView={mapView}
                setDistanceFilter={setDistanceFilter}
              />
            </div>
          </div>
        </div>
        <div className="map-webmap">
          <div className="webmap" ref={mapRef} />
          
        </div>
      </div>
      <SearchModal
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        searchTerm={searchTerm}
        setTrack={setTrack}
      />
    </div>
  );
};
