import React, { useEffect, useState } from "react";
import { List } from "semantic-ui-react";
import { ServiceListItemMobile } from "./ServiceListItemMobile";
import {gaMobileFilter} from '../../actions/gaevents';

interface CategoryResultsViewMobileProps {
  onlineResults: any[];
  geoResults: any[];
  setSelectedItem: Function;
  showOnlineResults: boolean;
}

export const CategoryResultsViewMobile = ({
  onlineResults,
  geoResults,
  setSelectedItem,
  showOnlineResults
}: CategoryResultsViewMobileProps) => {
  const [resultsToShow, setResultsToShow] = useState<any[]>([]);

  useEffect(() => {
    if (showOnlineResults) {
           setResultsToShow(onlineResults);
      gaMobileFilter(true);
    } else {
      //already sorted
      setResultsToShow(geoResults);
      gaMobileFilter(false);

    }
  }, [showOnlineResults, onlineResults, geoResults]);

  return (
    <List selection divided relaxed>
      {resultsToShow.map((org) =>
        <ServiceListItemMobile
          item={org}
          setSelectedItem={setSelectedItem}
          key={org.PID}
        />
      )}
    </List>
  );
};
