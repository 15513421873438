import React from "react";
import { List } from "semantic-ui-react";
import { storeSearchHistory } from "../actions/searchActions";
import {gaProviderInfo} from '../actions/gaevents';

interface ServiceListItemProps {
  item: any;
  setSelectedItem: Function;
  highlightOnHover: Function;
}

export const ServiceListItem = ({
  item,
  setSelectedItem,
  highlightOnHover,
}: ServiceListItemProps) => {
  const { Address: address, OrgName: orgName } = item;
  const isOnline = address === "Online";
  return (
    <List.Item
      onClick={() => {
        setSelectedItem(item);
        storeSearchHistory(item);
        gaProviderInfo(orgName);
      }}
      onMouseEnter={() => highlightOnHover(item)}
      onMouseLeave={() => highlightOnHover("")}
      className="location-list"
    >
      <List.Content>
        <List.Header
          className={!isOnline ? "location-header" : "online-header"}
        >
          <div style={{ display: 'inline-flex', lineHeight: 'initial' }}
            className={
              !isOnline
                ? "location-header-inner servulink-geo-result-icon"
                : "online-header-inner servulink-online-result-icon"
            }
          >
            {orgName}
          </div>
        </List.Header>
      </List.Content>
    </List.Item>
  );
};
