import React, { useState } from "react";
import { Grid, Image, Button, Icon } from "semantic-ui-react";

const SupportContact = () => {
  const [clickedOpenArms, setClickedOpenArms] = useState(false);
  const [clickedLifeLine, setClickedLifeLine] = useState(false);
  const [clickedRespect, setClickedRespect] = useState(false);

  return (
    <div>
      <Grid centered stackable columns={3} className="support-contact-grid">
        <Grid.Row>
      
          <Grid.Column width={8}>
            <div className="contact-details lifeline">
              <Image
                src="/assets/images/logo-lifeline.svg"
                alt="open arms"
                left
                size="small"
              />
              <Button
                className="ui primary button"
                icon
                left
                target="_self"
                onClick={() => setClickedLifeLine(true)}
                href={clickedLifeLine ? "tel:131114" : undefined}
              >
                <Icon className="phone square" size="small" />
                {clickedLifeLine ? "131114" : "CALL NOW"}
              </Button>
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div className="contact-details openarms">
              <Image
                src="/assets/images/Openarms-logo.png"
                alt="open arms"
                centered
                size="small"
              />
              <Button
                className="ui primary button"
                icon
                target="_self"
                onClick={() => setClickedOpenArms(true)}
                href={clickedOpenArms ? "tel:1800011046" : undefined}
              >
                <Icon className="phone square" size="small" />
                {clickedOpenArms ? "1800011046" : "CALL NOW"}
              </Button>
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div className="contact-details 1800">
              <Image
                src="/assets/images/1800respect-logo.png"
                alt="1800 respect"
                right
                size="medium"
              />
              <Button
                className="ui primary button"
                icon
                target="_self"
                onClick={() => setClickedRespect(true)}
                href={clickedRespect ? "tel:1800011046" : undefined}
              >
                <Icon className="phone square" size="small" />
                {clickedRespect ? "1800737732" : "CALL NOW"}
              </Button>
            </div>
          </Grid.Column>
          
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default SupportContact;
