import React, { useEffect } from 'react';
import { Modal } from 'semantic-ui-react';
import { loadModules } from "esri-loader";

interface SearchModalProps {
    openSearch: any;
    setOpenSearch: Function;
    searchTerm: Function;
    setTrack: Function;
}

let search: any;

export const SearchModal = ({openSearch, setOpenSearch, searchTerm, setTrack}: SearchModalProps) => {

    useEffect(()=>{
        loadModules(
            [ "esri/widgets/Search", "esri/tasks/Locator" ],
            { css: true }
        ).then(([Search, Locator]) => {
            search = new Search({
                container: 'search-container',
                iconClass: 'none',
                includeDefaultSources: false,
                sources: [{
                    locator: new Locator({ url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer" }),
                    singleLineFieldName: "SingleLine",
                    countryCode: "AUS",
                    name: "ArcGIS Australia Geocoding Service",
                    placeholder: "My Location",
                }]
            });

            search.on("search-complete", (result: any) => {
                let searchText;
                if(result.searchTerm === '' && result.results[0].results.length > 0) {
                  searchText = result.results[0].results[0].name;
                  setTrack(true);
                } 
                else if (result.searchTerm === '') return;
                else searchText = result.searchTerm;
                searchTerm(searchText);
                setOpenSearch(false);
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openSearch])

    return (
        <Modal
            onClose={() => setOpenSearch(false)}
            open={openSearch}
            className='search-modal'
        >
        <Modal.Header>Enter Search Location</Modal.Header>
            <Modal.Content>
                <div id='search-container'></div>
            </Modal.Content>
        </Modal>
    )
}
