import React from "react";
import "semantic-ui-css/semantic.min.css";
import Mobile from 'is-mobile';
import { Button } from "semantic-ui-react";

const PrivacyPage = ({handleMenuClick}:any) => {

  return (
    <div className={Mobile() ? 'body-container' : 'legal-container'} style={{padding: 10}}>
        <h2>
            PRIVACY COMMITMENT
        </h2>
        <p>
            Servulink Pty Ltd ACN 634 691 197 and its Associated Entities as defined in the Corporations Act
            (Cth) 2001 (&quot;we&quot;, &quot;our&quot; or &quot;us&quot;), recognize the trust you are placing in us through your dealings with
            us, including in applying for a position with us. We do everything we can to maintain that trust. This
            is our personal standard. Under law, your rights to privacy are also protected. Privacy laws place
            strict requirements on us to treat certain information collected as confidential, to store your
            information securely and to allow you easy access to check and correct your information.
        </p>
        <h3>
            SCOPE OF OUR PRIVACY POLICY
        </h3>
        <p>
            Our Privacy Policy explains how we collect information about you and how we may store,
            maintain and use that information.
        </p>
        <h3>
            WHAT INFORMATION DO WE COLLECT DURING APPLICATION USE?
        </h3>
        <p>
            Servulink does not collect your personally identifiable information. 
            We may collect the following Application user information from you:
        </p>
        <ul>
            <li>your device location, searched locations, search queries, and service provider selections,</li>
            <li>demographic information: your gender, birth year, military background, serving status and service type.</li>
        </ul>
        <h3>
            WHY DO WE COLLECT THIS INFORMATION?
        </h3>
        <p>
            We use the collected information for the purpose of building a more intuitive application into the
            future, linking users up with service providers based on search history and preferences. We also use the collected
            information to report back to the service providers regarding the types of users searching for their services.
        </p>
        <h3>
            DO WE SHARE INFORMATION WITH THIRD PARTIES?
        </h3>
        <p>
            We may disclose user information we hold to our service provider contacts for the purpose of assessing
            their services performance, and improving their service delivery. If you are a user who feels uncomfortable with this information sharing
            you may turn off the logging of cookies on your device.
        </p>
        <p>
            Where applicable information is shared with these third parties, they are obliged to observe the
            confidential nature of such information and are prohibited from using any or all of this information
            other than for the purpose for which it was provided. We will also disclose information to a law
            enforcement agency if we are requested to do so by that agency in relation to suspected unlawful
            activity. We may also disclose user information as part of a visa application process or where
            required to do so by law.
        </p>
        <p>
            Your Servulink Application user information may be stored in a secure and encrypted form overseas (e.g. in data
            storage and cloud computing facilities operated by us (or by third parties on our behalf)).
        </p>
        <p>
            We do not sell or trade personal information.
        </p>
        <h3>
            USER INFORMATION ACCESS
        </h3>
        <p>
            If you wish to access, correct or delete any user information we hold about you, please contact
            our Privacy Manager on info@servulink.com.au.
        </p>
        <h3>
            SECURITY
        </h3>
        <p>
            We understand the importance of your personal information and have appropriate procedures in
            place to safeguard and secure the information we collect to prevent unauthorized access or
            disclosure, maintain data accuracy and ensure the appropriate use of information. We also take
            measures in respect of destroying or de-identifying user information that is no longer needed
            for any lawful purpose.
        </p>
        <h3>
            PRIVACY COMPLAINTS
        </h3>
        <p>
            If you believe that our organisation has breached your privacy rights in any way, or you would like to
            discuss any issues about our privacy policy please contact our Privacy Manager on
            info@servulink.com.au
        </p>
        <p>
            All such enquiries or complaints will be taken seriously and handled with impartiality and discretion.
            If you have complained about something which the relevant privacy laws cover, we will thoroughly
            investigate all aspects of your complaint including ascertaining the relevant facts and what your
            expectations are for any resolution. During our investigation we may need to seek further
            information from you as to the nature of or factual circumstances surrounding your complaint.
        </p>
        <p>
            We will endeavour to respond to you at all times promptly and to offer a practical solution which is
            consistent with our legal obligations. 
        </p>

        {Mobile() ? 
            <div style={{ padding: 10 }}>
                <Button
                    onClick={() =>handleMenuClick("Services and Support")}
                    positive
                    className={"ui right floated button"}
                >
                    {"Go Back"}
                </Button>
            </div>
        : null}
    </div>
  );
};

export default PrivacyPage
