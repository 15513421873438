import React, { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react';

interface DirectionsComponentProps {
  points: any,
}

const DirectionsComponent = ({points}: DirectionsComponentProps) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index
        setActiveIndex(newIndex)
    }

    return (
        <Accordion>
            <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={handleClick}
            >
                <Icon name='dropdown' />
                Directions
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
                <div className='esri-widget esri-widget--panel esri-directions__scroller'>
                    {points.map((item: any) => (
                        <p className='direction-point'>{item}</p>
                    ))}
                </div>
            </Accordion.Content>
        </Accordion>
    )
}

export default DirectionsComponent