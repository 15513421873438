import React from 'react'
import { Segment } from "semantic-ui-react";
import { RecentItemsAndFavouritesViewMobile } from '../../containers/mobile/RecentItemsAndFavouritesViewMobile';

interface MobileRecentComponentProps {
  setSelectedItem: Function;
  setRecentVisible: Function;
}

const MobileRecentComponent = ({ setRecentVisible, setSelectedItem }: MobileRecentComponentProps) => {
  return (
    <div className='body-container'>
      <div className='back-header-container'>
        <i className='arrow left icon mobile' onClick={() => setRecentVisible(false)} />
        <h4 className='ui center aligned header heading'>Favourites/ Recently Visited</h4>
      </div>
      <Segment className='georesults-segment' style={{ height: '86vh' }}>
        <RecentItemsAndFavouritesViewMobile setSelectedItem={setSelectedItem} />
      </Segment>
    </div>
  )
}

export default MobileRecentComponent;