import React from "react";
import { Button, Grid } from "semantic-ui-react";
import styled from "styled-components";

const PContainer = styled.p`
  font-weight: bold;
  width: 100%;
`;

const ServiceIcon = styled.img`
  width: 50px;
  height: 55px;
  padding-bottom: 5px;
  /*background: linear-gradient(45deg, #002060, #aaaaaa);*/
`;

interface CategoryItemProps {
  displayName: String;
  onClick: any;
  icon: any;
}

export const CategoryItem = ({
  displayName,
  onClick,
  icon,
}: CategoryItemProps) => {
  return (
    <Grid.Column mobile={4} largeScreen={8}>
      <Button className="service-grid-btn" onClick={onClick}>
        <ServiceIcon src={icon} />
        <PContainer>{displayName}</PContainer>
      </Button>
    </Grid.Column>
  );
};