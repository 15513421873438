import React, { useEffect, useState } from "react";
import { List, Button, Divider } from "semantic-ui-react";
import { loadRecentItems, loadFavourites } from "../../actions/dataHelper";
import { ServiceListItemMobile } from "./ServiceListItemMobile";
import {gaRecentlyVisitedFilterClicked,gaFavouriteFilterClicked} from '../../actions/gaevents';

interface RecentItemsAndFavouritesViewMobileProps {
  setSelectedItem: Function;
}

export const RecentItemsAndFavouritesViewMobile = ({
  setSelectedItem,
}: RecentItemsAndFavouritesViewMobileProps) => {
  const [visited, setVisited] = useState<any[]>([]);
  const [favourites, setFavourites] = useState<any[]>([]);
  const [showRecentlyVisited, setShowRecentlyVisited] = useState(true);
  const [showFavourites, setShowFavourites] = useState(false);

  useEffect(() => {
    loadRecentItems(setVisited, undefined);
    loadFavourites(setFavourites, undefined);
  }, []);


 const RecentClicked = () => {
    setShowRecentlyVisited(!showRecentlyVisited);
    gaRecentlyVisitedFilterClicked(showRecentlyVisited,showFavourites);

  }
  
   
 const FavouritesClicked = () => {
   setShowFavourites(!showFavourites);
   gaFavouriteFilterClicked(showFavourites,showRecentlyVisited);

  }




  return (
    <div>
      <React.Fragment>
        <Button.Group widths="2" className="result-filter">
          <Button
            className="result-filter-btn"
            toggle
            active={showRecentlyVisited}
            onClick={RecentClicked}
          >
            Recently Visited
          </Button>
          <Button
            className="result-filter-btn"
            toggle
            active={showFavourites}
            onClick={FavouritesClicked}
          >
            Favourites
          </Button>
        </Button.Group>

        <Divider />
        <List selection divided relaxed>
          {showRecentlyVisited
            ? visited
                .sort(function (a, b) {
                  return a.clickedTime > b.clickedTime ? -1 : 1;
                })
                .map((org) => (
                  <ServiceListItemMobile
                    item={org}
                    setSelectedItem={setSelectedItem}
                    key={org.PID}
                  />
                ))
            : showFavourites
            ? favourites.map((org) => (
                <ServiceListItemMobile
                  item={org}
                  setSelectedItem={setSelectedItem}
                  key={org.PID}
                />
              ))
            : ""}
        </List>
      </React.Fragment>
    </div>
  );
};

