import React, { useState } from "react";
import {
  Button,
  Header,
  Modal,
  Rating,
  Form,
  TextArea,
  Message,
  Radio,
} from "semantic-ui-react";
import { sendFeedback } from "../actions/searchActions";
import {gaFeedbackSubmitted} from "../actions/gaevents";

export const FeedbackComponent = () => {
  const [open, setOpen] = useState(false);
  const [submitted, isSubmitted] = useState(false);
  const [rating, setRating] = useState(8);
  const [feedback, setFeedback] = useState("");
  const [charLeft, setCharLeft] = useState(Number);
  const [position,setPosition] = useState<any>("I am a Veteran/Family Member");

  const findLeftChars = (feedbackText: any) => {
    let currentCharCount = feedbackText.length;
    let remainCharCount = 1380 - currentCharCount;
    setCharLeft(remainCharCount);
  };

  //for submitting feedback
  const submitFeedback = () => {
    let historyArray: any[] = JSON.parse(
      localStorage.getItem("recentSearches") || "[]"
    );
    let visitedPlaces: any[] = historyArray
      .sort((a, b) => (a.clickedTime > b.clickedTime ? -1 : 1))
      .map((item) => item.OrgName);
         
    if (position.includes("Provider"))  
    {
      gaFeedbackSubmitted(rating,feedback.length,true);
    }
    else
    {
      gaFeedbackSubmitted(rating,feedback.length,false);
    }
    let  arrayoflines :  any[] = feedback.split('\n');
       
       
    //console.log({ rating, position,feedback, visitedPlaces });
    sendFeedback(`${rating}/10`, position,arrayoflines, visitedPlaces);
    isSubmitted(true);
  };

  const onClose = () => {
    setRating(0);
    isSubmitted(false);
    setOpen(false);
    setPosition("I am a Veteran/Family Member");
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button inverted className="feedback-button">
          Give FeedBack
        </Button>
      }
    >
      <Modal.Header>We Welcome Your Feedback</Modal.Header>
      <Modal.Content>
        {!submitted && (
          <Modal.Description>
            <div>
              <Header size="small">How would you rate your experience?</Header>
              <div className="numberSlider">1 10</div>
              <input
                type="range"
                min={1}
                max={10}
                value={rating}
                onChange={(e: any) => setRating(e.target.value)}
                className="ratingSlider"
              />
              <br />
              <Rating rating={rating} maxRating={10} size="huge" />
            </div>
            <div style={{ marginTop: 10 }}>
              <Header size="small">Do you have any additional comments?</Header>
               <h5>(If you wish to you may leave an email address within your feedback comments)</h5>
              <Form>
                <TextArea
                  rows={2}
                  maxLength="1380"
                  onChange={(e: any) => {
                    setFeedback(e.target.value);
                    findLeftChars(e.target.value);
                  }}
                />
              </Form>
              Remaining:{charLeft}
              <Form style={{ marginTop: 10 }}>
                <Form.Field></Form.Field>
                <Form.Field>
                  <Radio
                    label="I am a Service Provider"
                    name="radioGroup"
                    value="I am a Service Provider"
                    checked={position === "I am a Service Provider"}
                    onChange={(e: any, { value }) => setPosition(value)}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="I am a Veteran/Family Member"
                    name="radioGroup"
                    value="I am a Veteran/Family Member"
                    checked={position === "I am a Veteran/Family Member"}
                    onChange={(e: any, { value }) => setPosition(value)}
                  />
                </Form.Field>
              </Form>
            </div>
          </Modal.Description>
        )}
        {submitted && (
          <Modal.Description>
            <Message positive>
              <Message.Header>Thank you for your feedback</Message.Header>
            </Message>
          </Modal.Description>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={submitted ? onClose : submitFeedback} positive>
          {submitted ? "Close" : "Send"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
