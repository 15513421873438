import React from "react";
import { Grid } from "semantic-ui-react";
import styled from "styled-components";

const BannerContainer = styled.div`
  position: relative;
  z-index: 999;
  background-color: #002060;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 8px;
  box-shadow: 0 1px 5px #000000;
`;

const HeaderLogo = styled.img`
  max-height: 45px;
`;
interface IState {
  showMenu: boolean;
  showFilter: boolean;
}

class HeaderComponent extends React.Component {
  state: IState = {
    showMenu: false,
    showFilter: false,
  };

  render() {
    return (
      <BannerContainer>
        <Grid.Row>
          <a target="_blank" href="https://www.servulink.com.au/" rel="noopener noreferrer">
              <HeaderLogo src="/assets/images/servulink-logo-short.png" />
            </a>
        </Grid.Row>
        <Grid.Row>
          <span style={{ color: "white" }}>
           Servulink Digital Hub
          </span>
        </Grid.Row>
      </BannerContainer>
    );
  }
}

export default HeaderComponent;
