import React, {useState, useEffect, useRef} from "react";
import {Segment, Sidebar, Button, Modal} from "semantic-ui-react";
//import {FilterBar} from "./MobileFilterBar.component";
import {sleep, useIsMount} from "../../actions/generalHelper";
import {ServiceItem} from "../../containers/ServiceItem";
import {
    loadDirectionLayer,
    loadFeatureLayer,
    loadMap,
    loadMapSearch,
    getDirections,
    getGeoResults,
    filterLayerView,
    getMapZoom
} from "../../actions/mapHelper";
import {
    getOnlineData,
    getOnlineResults
} from "../../actions/dataHelper";
import {onItemSelect, getMilitaryStatus} from '../../actions/searchActions';
import {CategoryResultsViewMobile} from "../../containers/mobile/CategoryResultsViewMobile";
import * as turf from '@turf/turf';

interface MobileMapComponentProps {
    selectedCategory: any;
    searchText: any;
    setOpenSearch: Function;
    selectedItem: any;
    setSelectedItem: Function;
    selectedItemRef: any;
    recentVisible: any;
    onlineSelected: any;
    myLocation: any;
    setMyLocation: Function;
    inPersonClicked: any;
}

let arcGISMap: any;
let mapView: any;
let search: any;
let servufeatureLayer: any;
let directionsLayer: any;
let popup: any;

const MobileMapComponent = ({
                                selectedCategory,
                                searchText,
                                setOpenSearch,
                                selectedItem,
                                setSelectedItem,
                                selectedItemRef,
                                recentVisible,
                                onlineSelected,
                                myLocation,
                                setMyLocation,
                                inPersonClicked,
                            }: MobileMapComponentProps) => {
    const [distanceFilter, setDistanceFilter] = useState(0);
    const [directionsVisible, setDirectionsVisible] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(false);
    const [mapUpdates, setMapUpdates] = useState(0);
    const mapRef = useRef(null);
    const isMount = useIsMount();
    const militaryStatus = getMilitaryStatus();
    const [onlineResults, setOnlineResults] = useState<any[]>([]);
    const [geoResults, setGeoResults] = useState<any[]>([]);
    const [featureLayerView, setFeatureLayerView] = useState<any>();
    const [zoomInToNearest, setZoomInToNearest] = useState<boolean>(false);
    const [showZoomOutModal, setShowZoomOutModal] = useState<boolean>(false);
    const [canShowZoomOutModal, setCanShowZoomOutModal] = useState<boolean>(true);

    useEffect(() => {
        initialLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialLoad = async () => {
        ({arcGISMap, mapView} = await loadMap(mapRef));
        ({servufeatureLayer} = await loadFeatureLayer({
            arcGISMap,
            mapView,
            isMobile: true,
            setMapUpdates,
            setFeatureLayerView
        }));
        ({directionsLayer} = await loadDirectionLayer(arcGISMap, mapView, true));
        search = await loadMapSearch({
            mapView,
            selectedItemRef,
            setMyLocation,
            getDirections: (geometry: any) =>
                getDirections({
                    to: geometry,
                    isMobile: true,
                    directionsLayer,
                    search,
                    mapView,
                    featurePopup: popup,
                    setOpenSearch,
                    setDirectionsVisible,
                    setButtonVisible,
                }),
            isMobile: true,
        });
    };

    const onFilterLayerView = async (isCategoryChange: boolean = false, isMobile: boolean = true) => {
        await filterLayerView({
            isCategoryChange,
            isMobile,
            selectedItem,
            featureLayerView,
            getPopupData,
            onItemSelect,
            selectedCategory,
            directionsLayer,
            search,
            myLocation,
            distanceFilter,
            militaryStatus
        });
    };

    const usePrevious = (value: any) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const prevFeatureLayerView = usePrevious(featureLayerView);

    useEffect(() => {
        setZoomInToNearest(false);
        if (featureLayerView) {
            onFilterLayerView(true);
            if (!prevFeatureLayerView) {
                sleep(250).then(() => mapView.goTo({center: myLocation, zoom: 10}));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategory, featureLayerView]);

    useEffect(() => {
        if (!featureLayerView || featureLayerView.isUpdating() || !selectedCategory) return;
        featureLayerView.queryFeatureCount().then((featureCount: any) => {
            if (featureCount === 0) {
                setShowZoomOutModal(true);
            } else if (!zoomInToNearest) {
                setZoomInToNearest(true);
                featureLayerView.queryFeaturesJSON().then((json: any) => {
                    const points = json.features.map((f: any) => [f.geometry.x, f.geometry.y]);
                    const cen = turf.centroid(turf.points(points));
                    sleep(250).then(() => mapView.goTo({center: cen, zoom: 10}));
                }).catch((e: any) => console.log(e));
            }
        }).catch((e: any) => console.log(e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureLayerView, mapUpdates, selectedCategory, featureLayerView?.isUpdating()]);

    useEffect(() => {
        filterGeoResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapUpdates, selectedCategory]);

    useEffect(() => {
        if (onlineSelected) {
            fetchOnlineData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlineSelected]);

    useEffect(() => {
        if (selectedCategory && inPersonClicked) setCanShowZoomOutModal(false);
    }, [selectedCategory, inPersonClicked]);

    const filterGeoResults = async () => {
        if (!selectedItem && selectedCategory) {
            try {
                const geoResults = await getGeoResults({featureLayerView, servufeatureLayer, myLocation});
                setGeoResults(geoResults);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const fetchOnlineData = async () => {
        const csvResult = await getOnlineData();
        const militaryStatus = getMilitaryStatus();
        const onlineResults = await getOnlineResults({selectedCategory, csvResult, militaryStatus});
        setOnlineResults(onlineResults);
    }

    useEffect(() => {
        if (myLocation && !selectedItem && inPersonClicked) {
            onFilterLayerView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myLocation]);

    useEffect(() => {
        if (!isMount) {
            if (!myLocation) setOpenSearch(true);
            else {
                onFilterLayerView();
                sleep(500).then(() => mapView.goTo({
                    center: myLocation,
                    zoom: getMapZoom(distanceFilter),
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distanceFilter]);

    useEffect(() => {
        if (search) search.search(searchText);
    }, [searchText]);

    useEffect(() => {
        if (!isMount) {
            onFilterLayerView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem]);

    useEffect(() => {
        if (!isMount) {
         //   setDistanceFilter(20); removed by shane
            if (myLocation) {
                mapView.goTo({
                    center: myLocation,
                    zoom: 10,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inPersonClicked]);

    function getPopupData(selectedItem: any) {
        popup = mapView.popup;
        
    let ph = selectedItem.Phone;
 let py = selectedItem.PhonePretty;
 let phoner = "";
 if (selectedItem.PhonePretty == "No Number Provided")
 {
  phoner = '<p>Phone: No Number Provided </p>' +   
   "Specialises in:" +
            "<br/><br/>" +
            selectedItem.Specialises;
 }
 else
 {
 phoner =     "<br/><br/>" +
          '<p>Phone: <a href="tel:'+ ph + '">' +  py +  '</a> </p>' +
            "Specialises in:" +
            "<br/><br/>" +
            selectedItem.Specialises;
 //let samp = '<a href=“tel:0419223223”>mynum</a>';
 }  
        
        mapView.when(function () {
            popup.open({
               title: selectedItem.OrgName,
               content: `<div>${selectedItem.Address}</div> <a href='${selectedItem.Website}' >${selectedItem.Website}</a>`
               + 
            phoner,
               location: selectedItem.geometry,
               dockEnabled: true,
             });
            mapView.goTo({
                center: selectedItem.geometry,
                zoom: 11,
            });
        });
    }

    const renderMap = () => {
        return (
            <React.Fragment>
                {!recentVisible
                //&& <FilterBar
                 //   distanceFilter={distanceFilter}
                  //  setDistanceFilter={setDistanceFilter}
            //    />
                    
                }
                <div className="mobile-webmap" ref={mapRef} style={onlineSelected ? {display: 'none'} : {}}/>
            </React.Fragment>
        );
    }

    const onWarningModalClose = () => {
        setShowZoomOutModal(false);
        setCanShowZoomOutModal(false);
    };

    return (
        <div>
            {renderMap()}
            {!directionsVisible && selectedItem && !recentVisible
            && <div className="go-back-mobile-button-container">
                <Button
                    className="go-back-mobile-button"
                    basic color='blue'
                    onClick={() => {
                        setSelectedItem(undefined);
                        setButtonVisible(false);
                    }}>
                    <i
                        className="arrow left icon mobile black"
                        style={{display: "contents"}}
                    />
                    <span className="go-back-text"> Go Back</span>
                </Button></div>}

            <Modal
                size={'mini'}
                open={canShowZoomOutModal && showZoomOutModal}
                onClose={onWarningModalClose}
            >
                <Modal.Header>No service providers in the immediate area</Modal.Header>
                <Modal.Content>
                    <p>Please zoom out to see the closest providers.</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={onWarningModalClose}>
                        Ok
                    </Button>
                </Modal.Actions>
            </Modal>

            {buttonVisible && directionsVisible && <button
                className="ui mini fluid button"
                onClick={() => setDirectionsVisible(true)}
            >
                <i
                    className={directionsVisible ? "angle down icon" : "angle up icon"}
                />
                Directions
            </button>}

            <Sidebar.Pushable className="map-result-container">
                <Sidebar
                    animation="overlay"
                    direction="bottom"
                    onHide={() => setDirectionsVisible(false)}
                    visible={directionsVisible}
                >
                    <div
                        id="directions-container"
                        className="esri-widget esri-widget--panel esri-directions__scroller"
                    >
                    </div>
                </Sidebar>
                <Sidebar.Pusher dimmed={directionsVisible}>
                    <Segment
                        className="georesults-segment"
                        style={{
                            ...(onlineSelected
                                ? {height: '84vh'}
                                : buttonVisible ? {height: '25vh'} :
                                    selectedItem ? {height: '30vh'} :
                                        {height: '38vh'}),
                            border: 'unset',
                            boxShadow: 'unset'
                        }}>
                        {selectedItem
                            ? <ServiceItem
                                orgDetails={selectedItem}
                                onDirections={() =>
                                    getDirections({
                                        to: selectedItem.geometry,
                                        isMobile: true,
                                        directionsLayer,
                                        myLocation,
                                        search,
                                        mapView,
                                        featurePopup: popup,
                                        setOpenSearch,
                                        setDirectionsVisible,
                                        setButtonVisible,
                                    })
                                }
                            />
                            :
                            <CategoryResultsViewMobile
                                onlineResults={onlineResults}
                                geoResults={geoResults}
                                showOnlineResults={onlineSelected}
                                setSelectedItem={setSelectedItem}/>}

                    </Segment>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </div>
    );
};

export default MobileMapComponent;
