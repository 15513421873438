import React, { useState, useEffect } from 'react';
import { Modal, Dropdown, Button, Form } from 'semantic-ui-react';
import Cookies from "universal-cookie";
import aboutme from "../configs/aboutme.json";
import {gaPersonalInfoSubmitted,gaPersonalInfoSkipped} from "../actions/gaevents";

let profileArray: any[] = [];
let maxOffset = 120;
let thisYear = 2020;
let allYears: any[] = [];
for (let x = 0; x <= maxOffset; x++) {
  allYears.push({
    key: `${thisYear - x}`,
    text: thisYear - x,
    value: `${thisYear - x}`,
  });
}

const ProfileModal = () => {
    const [openModal, setOpenModal] = useState(false);
    const cookies = new Cookies();

    useEffect(() => {
      let storedCookies = cookies.getAll();
      if(!storedCookies.gender && !storedCookies.birth_year && !storedCookies.status && !storedCookies.military && !storedCookies.service && !storedCookies.profileSkipped) {
        setOpenModal(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setCookieValue(key: any, value: any) {
      profileArray[key] = value;
    };

    const handleSubmit = (skipped : boolean) => {

        let gender = "";
        let birth = 0;
        let servingstatus = "";
        let military = "";
        let servicetype = "";
      
        for (let item in profileArray) 
        {
          cookies.set(item, profileArray[item], { path: "/",  maxAge: 31536000});
          if (item == "gender")
            gender = profileArray[item];
          if (item == "birth_year")
            birth = profileArray[item];
          if (item == "status")
            servingstatus = profileArray[item];
          if (item == "military")
            military = profileArray[item];
          if (item == "service")
            servicetype = profileArray[item];
        }

      if (! skipped)
      {
        gaPersonalInfoSubmitted(gender,birth,servingstatus,military,servicetype);
      }
      else
      {
        cookies.set("profileSkipped", "skipped",  {path: "/",  maxAge: 31536000});
        gaPersonalInfoSkipped();
      }
      setOpenModal(false);
    }

    return (
        <Modal
            onClose={() => setOpenModal(false)}
            open={openModal}
            dimmer={'blurring'}
            className='profile-modal'
        >
        <Modal.Header>Welcome to the Servulink Digital Hub</Modal.Header>
        <Form style={{ marginTop: 5, marginLeft : 20 ,marginRight : 15 }}>
         <Modal.Header as= "h5"  size = 'tiny' >If you would like to assist us with improving the Digital Hub, please fill in any of the following information.</Modal.Header>
         <Modal.Header as= "h5" className = 'ui red header' size = 'tiny' >Please Note: <br/>This information is anonymous and assists us to understand our users. <br/> 
         It is completely optional and has no bearing on any searches you make.</Modal.Header>
         </Form>
        
            <Modal.Content>
                <div className="selection-dropdown-container">
                    Gender
                    <Dropdown
                    placeholder="Gender"
                    fluid
                    selection
                    options={aboutme.gender}
                    onChange={(event, data) => setCookieValue("gender", data.value)}
                    />
                    BirthYear
                    <Dropdown
                    placeholder="Birth Year"
                    fluid
                    selection
                    options={allYears}
                    onChange={(event, data) => setCookieValue("birth_year", data.value)}
                    />
                    Status
                    <Dropdown
                    placeholder="Status"
                    fluid
                    selection
                    options={aboutme.status}
                    onChange={(event, data) => setCookieValue("status", data.value)}
                    />
                    Military Background
                    <Dropdown
                    placeholder="Military Background"
                    fluid
                    selection
                    options={aboutme.military}
                    onChange={(event, data) => setCookieValue("military", data.value)}
                    />
                    Service Type
                    <Dropdown
                    placeholder="Service Type"
                    fluid
                    selection
                    options={aboutme.service}
                    onChange={(event, data) => setCookieValue("service", data.value)}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => handleSubmit(true)}>skip</Button>
                <Button
                content="Submit"
                labelPosition='right'
                icon='checkmark'
                onClick={() => handleSubmit(false)}
                primary
                />
            </Modal.Actions>
        </Modal>
    )
}

export default ProfileModal
