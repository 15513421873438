import Cookies from "universal-cookie";
import { serviceClickApi, feedbackApi } from "../config.json";
import { getOnlineData } from "./dataHelper";
import { getFeatureLayer } from "./mapHelper";

export const getMilitaryStatus = () => {
  const cookies = new Cookies();
  return cookies.get('status') || undefined;
}

export const onItemSelect = (pid: string, serviceName: string) => {
  const cookies = new Cookies();
  const data = {
    gender: cookies.get("gender"),
    birthYear: cookies.get("birth_year"),
    status: cookies.get("status"),
    militaryBackground: cookies.get("military"),
    serviceType: cookies.get("service"),
    pid,
    serviceName
  };

  fetch(serviceClickApi, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const sendFeedback = (
  rating: String,
  position: String | Number | undefined,
  feedback: Array<String>,
  viewedItems: Array<String>
) => {
  fetch(feedbackApi, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ rating, position, feedback, viewedItems }),
  });
};

export const storeSearchHistory = (item: any) => {
  const { Address, OrgName, OpenHours, Website } = item;
  let PID = item.id ? item.id : item.PID
  let availableIndex: any;
  let clickedTime = new Date().toLocaleString();
  var searchHistory = JSON.parse(
    localStorage.getItem("recentSearches") || "[]"
  );
  if (searchHistory.length > 0) {
    if (searchHistory.length === 50) {
      searchHistory.splice(0, 1);
    }
    availableIndex = searchHistory.findIndex((element:any)=> element.id===PID || element.PID===PID)
  }
  if (availableIndex>=0) {
    searchHistory.splice(availableIndex, 1);
    searchHistory.push({
      PID,
      clickedTime,
      OrgName,
      Address,
      Website,
      OpenHours,
    });
  } else {
    searchHistory.push({
      PID,
      clickedTime,
      OrgName,
      Address,
      Website,
      OpenHours,
    });
  }
  localStorage.setItem("recentSearches", JSON.stringify(searchHistory));
};

export const getSearchResultsData = async (searchText: String, servufeatureLayer?:any, csvResult?:any) => {
  if(!servufeatureLayer){
    servufeatureLayer = await getFeatureLayer();
  }
  if(!csvResult){
    csvResult = await getOnlineData();
  }
  const query = servufeatureLayer.createQuery();
  query.where = `lower(OrgName) like '%${searchText.toLowerCase()}%' or lower(Address) like '%${searchText.toLowerCase()}%'`;
  query.outFields = ["*"];
  let geoResults = await servufeatureLayer.queryFeatures(query);
  geoResults = geoResults.features.map((obj: any) => {
    return { geometry: obj.geometry, ...obj.attributes };
  });
  let onlineResults = csvResult.filter(
    (item: any) =>
      item.Address === "Online" && //online results only
      ((item.OrgName &&
        item.OrgName.toLowerCase().includes(searchText.toLowerCase())) || //orgname has search text
        (item.Website &&
          item.Website.toLowerCase().includes(searchText.toLowerCase())))
  ); //web address has search text
  return [...geoResults, ...onlineResults];
};