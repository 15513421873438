import React from "react";
import "semantic-ui-css/semantic.min.css";
import Mobile from 'is-mobile';
import { Button } from "semantic-ui-react";

const DisclaimerPage = ({handleMenuClick}:any) => {

  return (
    <div className="legal-container">
      {Mobile() ? null : <h2>Disclaimer</h2>}
      <p>
        The content of this website is provided for information and communication purposes only. While care is
        taken to ensure the information is accurate and reliable, Servulink makes no warranty, either express or
        implied, concerning the suitability, reliability, availability, timeliness or accuracy of the content of this
        website for any purpose. By accessing this website you acknowledge and agree that you will not rely on the
        information contained in the website and that Servulink will not be liable for the information provided on this
        website or incorporated into it by reference or for the use of such information. Information is provided on
        the basis that all persons accessing the site are solely responsible for assessing the relevance and
        accuracy of its content. Content may not be free from errors, omissions or inconsistencies. It is
        recommended that users exercise care with its use. No liability is accepted for any information or services
        which may appear in any other format. Servulink accepts no responsibility or liability for the condition or
        content of any information or services which may appear on any linked websites.
      </p>
      {Mobile() ? 
        <div style={{ padding: 10 }}>
          <Button
              onClick={() =>handleMenuClick("Services and Support")}
              positive
              className={"ui right floated button"}
          >
              {"Go Back"}
          </Button>
        </div>
       : null}
    </div>
  );
};

export default DisclaimerPage
