import React from 'react'
import { Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'
 

const FooterContainerMobile = styled.footer`    
  bottom:0;                          
  right:0;
  background-color: #292828;
  color: white;
  padding: 3rem 3.5rem 3rem 2.5rem;
`
const LiContainer = styled.li`
  font-family: 'Courgette', cursive;
  font-size: 14px;
  color: #aaaaaa;
  margin: 1.5rem 0 0;
  list-style-type: none;
  text-align: left;
`
const PContainer = styled.p`
  color: #cccccc;
  font-family: 'Heebo', sans-serif;
  font-size: 16px;
  text-align: left;
`

export const MobileContactUs=()=> {
    return (
      <FooterContainerMobile>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column width={15}>
              <Image src="/assets/images/servulink-logo-white.png" size="small" />
              <LiContainer>
                Empowering veterans and their families to connect with services,
                support and community
              </LiContainer>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={15}>

              <div className="social-wrapper-mobile" style={{ float: "left",display:"ruby"}}>
                <a
                  href="https://www.servulink.com.au/"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ textAlign: "left", marginTop: 0, marginBottom: 0 }}
                >
                  <button className="ui circular inverted icon button">
                    <i className="opera icon"></i>
                  </button>
                </a>

                <a
                  href="https://www.facebook.com/servulink/?ref=py_c"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ textAlign: "left", marginTop: 0, marginBottom: 0 }}
                >
                  <button className="ui circular inverted icon button">
                    <i className="facebook icon"></i>
                  </button>
                </a>

                <a
                  href="https://www.instagram.com/servulink_/"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ textAlign: "left", marginTop: 0, marginBottom: 0 }}
                >
                  <button className="ui circular inverted icon button">
                    <i className="instagram icon"></i>
                  </button>
                </a>

                <a
                  href="https://www.linkedin.com/company/servulink/"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ textAlign: "left", marginTop: 0, marginBottom: 0 }}
                >
                  <button className="ui circular inverted icon button">
                    <i className="linkedin icon"></i>
                  </button>
                </a>
                <a
                  href="mailto:info@servulink.com.au"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ textAlign: "left", marginTop: 0, marginBottom: 0 }}
                >
                  <button className="ui circular inverted icon button">
                    <i className="envelope outline icon"></i>
                  </button>
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </FooterContainerMobile>
    );
};

 