import React from "react";
import "semantic-ui-css/semantic.min.css";
import styled from "styled-components";
import { Button } from "semantic-ui-react";

const HeaderLogo = styled.img`
  height 120%;
   width:120%;
margin-left : -40px;
margin right : auto;
   display : block;
  

`;


export const LandingPage = ({ setShowLandingPage }: any) => {
  return (
    
    
    
    <div className="landing">
      <div className="landing-inner">

       


        <HeaderLogo src="/assets/images/newserve.png"  />


         <h3 style={{ margin: "-30px 0px 30px 0px", color : "white" ,fontSize : "17px" }}>
        Connecting our Defence and Veteran Community<br></br>
        to Services and Support
        </h3>
        
      
        
        <Button className="landingButton" onClick={()=>setShowLandingPage(false)}>
          Enter
        </Button>
         
        
       
        
      </div>
    </div>
  );
};
