import { loadModules } from "esri-loader";
import React, { useEffect, useState } from "react";
import { Icon, Search } from "semantic-ui-react";
import { getSearchResultsData } from "../actions/searchActions";

interface ServiceSearchProps {
  setSelectedItem: Function;
  searchTerm: Function;
  csvResult?: any;
  servufeatureLayer?: any;
  isMobile?: boolean;
}

let search: any;

export const ServiceSearch = ({
  setSelectedItem,
  searchTerm,
  csvResult,
  servufeatureLayer,
  isMobile
}: ServiceSearchProps) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    loadSearch();
  }, []);
  const loadSearch = async () => {
    const [Search, Locator] = await loadModules(
      ["esri/widgets/Search", "esri/tasks/Locator"],
      { css: true }
    );
    search = new Search({
      includeDefaultSources: false,
      sources: [
        {
          locator: new Locator({
            url:
              "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
          }),
          singleLineFieldName: "SingleLine",
          countryCode: "AUS",
          name: "Address",
          placeholder: "Search by Address",
        },
      ],
    });
  };

  const onSearch = async (event: any, { value: searchText }: any) => {
    if (searchText) {
      setIsSearching(true);
      let results: any = await getSearchResultsData(searchText, servufeatureLayer, csvResult);
      results = results.map((item: any) => {
        let isOnline = item.Address === "Online";
        return {
          title: item.OrgName,
          description: (
            <div style={{ marginLeft: "20px" }}>
              {isOnline ? item.Website : item.Address}
            </div>
          ),
          item,
          key: item.PID,
        };
      });
      if (!isMobile) {
        let addressResults = await search.suggest(searchText);
        if (addressResults?.results[0]?.results) {
          addressResults?.results[0]?.results.forEach(
            ({ text: name, key }: any) => {
              results.push({
                title: name,
                isAddress: true,
                key: key,
              });
            }
          );
        }
      }
      setSearchResults(results);
      setIsSearching(false);
    } else {
      setSearchResults([]);
    }
  };

  const renderResult = ({ title, isAddress, item }: any) => {
    let isOnline = !isAddress && item.Address === "Online";
    return (
      <React.Fragment>
        <div>
          {isOnline ? (
            <Icon className="globe" color="blue" />
          ) : isAddress ? (
            <Icon className="map" color="green" />
          ) : (
            <Icon className="map marker alternate" color="red" />
          )}
          <span className="title">{title}</span>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="search-wrapper">
        <Search
          loading={isSearching}
          onResultSelect={(e, data) =>
            data.result.isAddress
              ? searchTerm(data.result.title)
              : setSelectedItem(data.result.item)
          }
          onSearchChange={onSearch}
          results={searchResults.length ? searchResults : []}
          placeholder={`Search by Service Name${!isMobile ? ' or Address' : ''}`}
          resultRenderer={renderResult}
        />
      </div>
    </React.Fragment>
  );
};
