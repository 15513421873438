import React, { useState } from 'react'
import styled from 'styled-components'
import { getSearchLocation } from '../../actions/mapHelper'
import { ServiceSearch } from '../../containers/ServiceSearch'

interface MobileHeaderComponentProps {
  title: any;
  setMenuVisible: Function;
  setSearchSelectedItem: Function;
  setMainLocation: Function;
  handleMenuClick:Function;
}

const BannerContainer = styled.div`
  display:flex;
  background-color: #002060;
  width: 100%;
  align-items: center;
  margin: auto;
  height: 7vh;
`

const MobileHeaderComponent = ({ title, setMenuVisible, setSearchSelectedItem, setMainLocation,handleMenuClick }: MobileHeaderComponentProps) => {
  const [showSearch, setShowSearch] = useState(false);

  const onSearch = async (searchtext: String) => {
    await getSearchLocation(searchtext);
  }

  const homeView = () => {
    setSearchSelectedItem(undefined);
    handleMenuClick("Services and Support");
  };

  return (
    <React.Fragment>
      <BannerContainer>
        <i className="inverted big bars icon" onClick={() => setMenuVisible(true)} />
        <i className="inverted big home icon" onClick={() => homeView()}/>
        <h3 className="ui inverted center aligned header" style={{fontSize:"2.5vh"}}>{title}</h3>
        <i className="inverted big search icon" onClick={() => setShowSearch(show => !show)} />
      </ BannerContainer>
      {showSearch && <ServiceSearch setSelectedItem={(item: any) => { setSearchSelectedItem(item); setShowSearch(false); }} searchTerm={onSearch} isMobile={true} />}
    </React.Fragment>

  )
}

export default MobileHeaderComponent;
