import React from "react";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

const PContainer = styled.p`
  font-weight: bold;
  font-size: 3.7vw;
  width: 100%;
`;

const ServiceIcon = styled.img`
  width: 13vw;
  height: 13vw;
  /*background: linear-gradient(45deg, #002060, #aaaaaa);*/
`;

interface CategoryItemMobileProps {
  displayName: String;
  onClick: any;
  icon: any;
}

export const CategoryItemMobile = ({
  displayName,
  onClick,
  icon,
}: CategoryItemMobileProps) => {
  return (
    <div className="mobile-button center aligned button-container" style={{margin:"auto", marginBottom:"3vh"}}>
      <Button className="ui icon button inverted" onClick={onClick}>
        <ServiceIcon src={icon} />
      </Button>
      <PContainer>{displayName}</PContainer>
    </div>
  );
};
