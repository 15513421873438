import React from "react";
import { Grid } from "semantic-ui-react";
import { FooterComponent } from "../components/Footer.component";
import SupportContact from "../components/SupportContact.component";
import categories from "../configs/categories.json";
import { CategoryItem } from "./CategoryItem";
import {gaCategoryInfo} from '../actions/gaevents';

interface CategoryListViewProps {
  setSelectedCategory: Function;
  setIsRecentItemView: Function;
}

export const CategoryListView = ({
  setSelectedCategory,
  setIsRecentItemView,
}: CategoryListViewProps) => {
  return (
    <React.Fragment>
      <Grid className="services-grid" padded>
        <Grid.Row>
          {categories.map(
            ({ category, relatedCategory, icon, displayName }: any) => (
              <CategoryItem
                displayName={displayName}
                icon={icon}
                onClick={() => {
                  setSelectedCategory(category);
                  gaCategoryInfo(category);
                }}
                key={category}
              />
            )
          )}
          <CategoryItem
            displayName="Favourites/ Recently Visited"
            icon="/assets/images/recent-visited.png"
            onClick={() => setIsRecentItemView(true)}
          />
        </Grid.Row>
      </Grid>

      <SupportContact />
      <FooterComponent />
    </React.Fragment>
  );
};