import React, { useEffect, useState } from "react";
import { Button, Divider, List } from "semantic-ui-react";
import { ServiceListItem } from "./ServiceListItem";
import categories from "../configs/categories.json";
import {gaInPersonFilterClicked,gaOnlineFilterClicked} from '../actions/gaevents';

interface CategoryResultsViewProps {
  onlineResults: any[];
  geoResults: any[];
  setSelectedItem: Function;
  highlightOnHover: Function;
  selectedCategory: any;
}

export const CategoryResultsView = ({
  onlineResults,
  geoResults,
  setSelectedItem,
  highlightOnHover,
  selectedCategory,
}: CategoryResultsViewProps) => {
  const [showOnlineResults, setShowOnlineResults] = useState(false);
  const [showGeoResults, setShowGeoResults] = useState(true);
  const [resultsToShow, setResultsToShow] = useState<any[]>([]);

    useEffect(() => {
    if (showOnlineResults && showGeoResults) {
      //sort by name
      let results = [...onlineResults, ...geoResults].sort((a, b) =>
        a.OrgName.localeCompare(b.OrgName)
      );
      setResultsToShow(results);
    } else if (showOnlineResults) {

      setResultsToShow(onlineResults);
    } else if (showGeoResults) {
      //already sorted
      setResultsToShow(geoResults);
    } else {
      setResultsToShow([]);
    }
  }, [showGeoResults, showOnlineResults, geoResults, onlineResults]);

 const OnlineClicked = () => {
    setShowOnlineResults(!showOnlineResults);
    gaOnlineFilterClicked(showOnlineResults,showGeoResults);

  }
  
   
 const InPersonClicked = () => {
   setShowGeoResults(!showGeoResults);
   gaInPersonFilterClicked(showGeoResults,showOnlineResults);

  }

  return (
    <React.Fragment>
      <h3 className="category-title">
        {categories.find((i) => i.category === selectedCategory)?.displayName ||
          ""}
      </h3>
      <Button.Group widths="2" className="result-filter">
        <Button
          className="result-filter-btn"
          toggle
          active={showOnlineResults}
          onClick={OnlineClicked}
        >
          Online
        </Button>
        <Button
          className="result-filter-btn"
          toggle
          active={showGeoResults}
          onClick={InPersonClicked}
        >
          In-Person
        </Button>
      </Button.Group>

      <Divider />

      <List selection divided relaxed>
        {resultsToShow.map((org) => (
          <ServiceListItem
            item={org}
            setSelectedItem={setSelectedItem}
            highlightOnHover={highlightOnHover}
            key={org.PID}
          />
        ))}
      </List>
    </React.Fragment>
  );
};
