import React from "react";
import categories from "../../configs/categories.json";
import { CategoryItemMobile } from "./CategoryItemMobile";
import MobileSupportContact from "../../components/mobile/MobileSupportContact.component";
import {gaCategoryInfo} from '../../actions/gaevents';

interface CategoryListViewMobileProps {
  serviceClick: Function;
  setIsRecentItemView: Function;
}

export const CategoryListViewMobile = ({
  serviceClick,
  setIsRecentItemView,
}: CategoryListViewMobileProps) => {

  return (
        <div className="ui eight column grid padded doubling body-container" style={{width:"100%", padding:"3vh"}}>
          {categories.map(
            ({ category, icon, displayName }: any) => (
              <CategoryItemMobile
                displayName={displayName}
                icon={icon}
                onClick={() => {
                  serviceClick(category);
                  gaCategoryInfo(category);
                }}
                key={category}
              />
            )
          )}
          <CategoryItemMobile
            displayName="Favourites/ Recently Visited"
            icon="/assets/images/recent-visited.png"
            onClick={() => setIsRecentItemView(true)}
          />
          <MobileSupportContact />
        </div>
  );
};
