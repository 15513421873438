import React, { useEffect, useState } from "react";
import { Button, Divider, List } from "semantic-ui-react";
import { ServiceListItem } from "./ServiceListItem";
import { loadRecentItems, loadFavourites } from "../actions/dataHelper";
import {gaRecentlyVisitedFilterClicked,gaFavouriteFilterClicked} from '../actions/gaevents';

interface RecentItemsAndFavouritesViewProps {
  setSelectedItem: Function;
  highlightOnHover: Function;
  servufeatureLayer: any;
}
export const RecentItemsAndFavouritesView = ({
  setSelectedItem,
  highlightOnHover,
  servufeatureLayer,
}: RecentItemsAndFavouritesViewProps) => {
  const [visited, setVisited] = useState<any[]>([]);
  const [favourites, setFavourites] = useState<any[]>([]);
  const [showRecentlyVisited, setShowRecentlyVisited] = useState(true);
  const [showFavourites, setShowFavourites] = useState(false);
  useEffect(() => {
    loadRecentItems(setVisited, servufeatureLayer);
    loadFavourites(setFavourites, servufeatureLayer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


 const RecentClicked = () => {
    setShowRecentlyVisited(!showRecentlyVisited);
    gaRecentlyVisitedFilterClicked(showRecentlyVisited,showFavourites);

  }
  
   
 const FavouritesClicked = () => {
   setShowFavourites(!showFavourites);
   gaFavouriteFilterClicked(showFavourites,showRecentlyVisited);

  }



  return (
    <React.Fragment>
      <h3 className="category-title">Favourites/ Recently Visited</h3>
      <Button.Group widths="2" className="result-filter">
        <Button
          className="result-filter-btn"
          toggle
          active={showRecentlyVisited}
          onClick={RecentClicked}
        >
          Recently Visited
        </Button>
        <Button
          className="result-filter-btn"
          toggle
          active={showFavourites}
          onClick={FavouritesClicked}
        >
          Favourites
        </Button>
      </Button.Group>

      <Divider />
      <List selection divided relaxed>
        {showRecentlyVisited
          ? visited
              .sort(function (a, b) {
                return a.clickedTime > b.clickedTime ? -1 : 1;
              })
              .map((org) => (
                <ServiceListItem
                  item={org}
                  setSelectedItem={setSelectedItem}
                  highlightOnHover={highlightOnHover}
                  key={org.PID}
                />
              ))
          : showFavourites
          ? favourites.map((org) => (
              <ServiceListItem
                item={org}
                setSelectedItem={setSelectedItem}
                highlightOnHover={highlightOnHover}
                key={org.PID}
              />
            ))
          : ""}
      </List>
    </React.Fragment>
  );
};
