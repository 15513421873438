import React, { useState } from "react";
import "./styles/App.css";
import "semantic-ui-css/semantic.min.css";
import { LandingPage } from "./pages/Landing.page";
import HomePage from "./pages/Home.page";
import DisclaimerPage from "./pages/Disclaimer.page";
import PrivacyPage from "./pages/Privacy.page";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

const App = () => {
  const [showLandingPage, setShowLandingPage] = useState(true);
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/disclaimer" component={DisclaimerPage} />
          <Route path="/privacy" component={PrivacyPage} />
          <Route
            path="/"
            render={() =>
              showLandingPage ? (
                <LandingPage setShowLandingPage={setShowLandingPage} />
              ) : (
                <HomePage />
              )
            }
          />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
