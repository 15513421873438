import React from 'react'
import 'semantic-ui-css/semantic.min.css'
import { HomeComponent } from '../components/Home.component'
import { MobileHomeComponent } from '../components/mobile/MobileHome.component'
import Mobile from 'is-mobile';

class HomePage extends React.Component {
  render() {
    return (
      <div>
        {Mobile() ? <MobileHomeComponent /> : <HomeComponent />}
      </div>
    )
  }
}

export default HomePage