import React from "react";
import { loadModules } from "esri-loader";
import DirectionsComponent from "../components/Directions.component";
import ReactDOM from "react-dom";
import * as turf from "@turf/turf";
import { getFeatureLayerId } from './dataHelper';
import {setDefaultOptions} from "esri-loader";
import {gaGeolocate} from "./gaevents"
 
 setDefaultOptions({version: '4.22'});
let _servufeatureLayer: any;

export const loadMap = async (mapRef: any) => {
  const [ArcGISMap, MapView] = await loadModules(
    ["esri/Map", "esri/views/MapView"],
    { css: true }
  );
  let arcGISMap = new ArcGISMap({ basemap: "streets-navigation-vector" });
  let mapView = new MapView({
    container: mapRef.current,
    map: arcGISMap,
  });
  return { arcGISMap, mapView };
};

export const loadFeatureLayer = async ({
  arcGISMap,
  mapView,
  isMobile,
  setMapUpdates,
  setFeatureLayerView
}: any) => {
  const [FeatureLayer] = await loadModules(["esri/layers/FeatureLayer"], {
    css: true,
  });
  const template = {
    title: "{OrgName}",
    content: isMobile
      ? `<div><b>Address:</b></div> {Address} <div style={marginTop:5}><b>Website:</b></div> {Website}`
      : `{Address}<br/><br/><a target="_blank" href="{Website}">{Website}</a>`,
  };
  let { featureLayerId } = await getFeatureLayerId();
  let servufeatureLayer = new FeatureLayer({
    portalItem: {
      id: featureLayerId,
    },
    outFields: ["*"],
 //   popupTemplate: template,
  });
  arcGISMap.add(servufeatureLayer);
  mapView.whenLayerView(servufeatureLayer).then((view: any) => {
    view.watch("updating", function (value: any) {
      if (!value) {
        setMapUpdates((num: number) => ++num);
      }
    });
    setFeatureLayerView(view);
  });
  if (!isMobile) {
    await zoomToLayer(servufeatureLayer, mapView);
  }
  return { servufeatureLayer };
};

export const loadDirectionLayer = async (
  arcGISMap: any,
  mapView: any,
  isMobile: boolean = false
) => {
  const [GraphicsLayer, Graphic, Track] = await loadModules(
    ["esri/layers/GraphicsLayer", "esri/Graphic", "esri/widgets/Track"],
    {
      css: true,
    }
  );
  let directionsLayer = new GraphicsLayer();
  arcGISMap.add(directionsLayer);
  let tracker;
  if (!isMobile) {
    tracker = new Track({
      view: mapView,
      graphic: new Graphic({
        symbol: {
          type: "simple-marker",
          size: "15px",
          color: "blue",
          outline: {
            color: "#ffffff",
            width: "1.5px",
          },
        },
      }),
      geolocationOptions: {
        timeout: 5000,
      },
    });
  }
  return { directionsLayer, tracker };
};

export const loadMapSearch = async ({
  mapView,
  setMyLocation,
  setTrack,
  isMobile,
  selectedItemRef,
  getDirections,
}: any) => {
  const [Search] = await loadModules(["esri/widgets/Search"], {
    css: true,
  });
  let search = new Search({
    view: mapView,
    popupEnabled: false,
    autoNavigate: true,
  });
  if (isMobile) {
    search.on("select-result", function (result: any) {
      setMyLocation(result.result.feature);
      if (selectedItemRef.current) {
        getDirections(selectedItemRef.current.geometry);
      }
    });
  } else {
    search.on("select-result", (result: any) => {
      if (result.result.key === undefined) setTrack(true);
      setMyLocation(result.result.feature);
    });
  }


  search.watch("viewModel.state", function (state: any) {
    if (state === "ready") {
      var firstSource = search.allSources.getItemAt(0);
      if (firstSource) {
        firstSource.zoomScale = 100000;
      }
    }
  });

  return search;
};

export const getDirections = async ({
  to,
  isMobile,
  directionsLayer,
  myLocation,
  search,
  mapView,
  featurePopup,
  setOpenSearch,
  setDirectionsVisible,
  setButtonVisible,
}: any) => {
  const [RouteTask, RouteParameters, FeatureSet, Graphic] = await loadModules(
    [
      "esri/tasks/RouteTask",
      "esri/tasks/support/RouteParameters",
      "esri/tasks/support/FeatureSet",
      "esri/Graphic",
    ],
    { css: true }
  );
  let routeTask = new RouteTask({
    url:
      "https://utility.arcgis.com/usrsvcs/appservices/F3AQiOtolTH3vXr2/rest/services/World/Route/NAServer/Route_World/solve",
  });

  const addGraphic = (point: any) => {
    let graphic = new Graphic({
      symbol: {
        type: "simple-marker",
        color: "black",
        size: isMobile ? "5px" : "7px",
      },
      geometry: point,
      visible: isMobile ? false : true,
    });
    directionsLayer.graphics.add(graphic);
  };

  const getRoute = () => {
    let routeParams = new RouteParameters({
      stops: new FeatureSet({
        features: [
          ...([...(myLocation ? [myLocation] : [search.resultGraphic])]),
          ...directionsLayer.graphics.toArray(),
        ],
      }),
      returnDirections: true,
    });
    routeTask.solve(routeParams).then(function (data: { routeResults: any[] }) {
      console.log(data);
      data.routeResults.forEach(function (result) {
        result.route.symbol = {
          type: "simple-line",
          color: [5, 150, 255],
          width: 3,
        };
        gaGeolocate(result.route.attributes.Total_Kilometers,result.route.attributes.Total_TravelTime);
        directionsLayer.graphics.add(result.route);
        featurePopup.close();
        mapView.goTo(result.route.geometry.extent);
      });

      let directions_container: any;
      if (isMobile) {
        directions_container = document.getElementById("directions-container");
        directions_container.innerHTML = "";
      } else {
        let directions = document.createElement("div");
        directions.id = "directions-panel";
        mapView.ui.add(directions, "top-right");
      }

      let points: any[] = [];
      let features = data.routeResults[0].directions.features;
      features.forEach(function (result: any) {
        let distance = result.attributes.length * 1.609344;
        if (isMobile) {
          let direction = document.createElement("p");
          direction.className = "direction-point";
          direction.innerHTML = `${result.attributes.text} (${distance.toFixed(
            2
          )} km) - ${result.attributes.time.toFixed(2)} min)`;
          directions_container.appendChild(direction);
        } else {
          points.push(
            `${result.attributes.text} (${distance.toFixed(
              2
            )} km - ${result.attributes.time.toFixed(2)} min)`
          );
        }
      });
      if (!isMobile) {
        const accordian = <DirectionsComponent points={points} />;
        ReactDOM.render(accordian, document.getElementById("directions-panel"));
      } else {
        setDirectionsVisible(true);
        setButtonVisible(true);
      }
    });
  };
  if (search.resultGraphic || myLocation) {
    directionsLayer.removeAll();
    addGraphic(to);
    getRoute();
  } else setOpenSearch(true);
};

export const getGeoResults = async ({
  featureLayerView,
  servufeatureLayer,
  myLocation,
}: any) => {
  if (featureLayerView) {
    const objectIds = await featureLayerView.queryObjectIds();
    if (!objectIds.length) {
      return [];
    }
    const query = featureLayerView.createQuery();
    query.objectIds = objectIds;
    const results = await servufeatureLayer.queryFeatures(query);
    let geoArr = [];
    for (let i = 0; i < results.features.length; i++) {
      const obj = results.features[i].attributes;
      obj.geometry = results.features[i].geometry;
      geoArr.push(results.features[i].attributes);
    }
    if (myLocation) {
      const { geometry }: any = myLocation;
      let current = turf.point([geometry.longitude, geometry.latitude]);
      geoArr = geoArr.sort((a, b) => {
        let aPoint = turf.point([a.geometry.longitude, a.geometry.latitude]);
        let bPoint = turf.point([b.geometry.longitude, b.geometry.latitude]);
        return (
          turf.distance(current, aPoint, { units: "miles" }) -
          turf.distance(current, bPoint, { units: "miles" })
        );
      });
    } else {
      geoArr = geoArr.sort((a, b) => a.OrgName.localeCompare(b.OrgName));
    }
    return geoArr;
  } else {
    return [];
  }
};

export const zoomToLayer = async (layer: any, mapView: any) => {
  const { extent } = await layer.queryExtent();
  await mapView.goTo(extent);
};

export const filterLayerView = async ({
  isCategoryChange,
  isMobile,
  selectedItem,
  featureLayerView,
  getPopupData,
  onItemSelect,
  selectedCategory,
  directionsLayer,
  search,
  setTrack,
  myLocation,
  distanceFilter,
  militaryStatus,
}: any) => {
  // let statement = await fetchCookieFilterStatement(militaryStatus);
  if (featureLayerView) {
    if (selectedItem) {
      const { PID: currentID, Address: address, OrgName: serviceName }: any = selectedItem;
      featureLayerView.filter = {
        where: "PID='" + currentID + "'",
      };
      if (address !== "Online") {
        getPopupData(selectedItem);
      }
      onItemSelect(currentID, serviceName);
    } else {
      let distanceFilters = getDistanceFilters(myLocation, distanceFilter);
      if (selectedCategory) {
        featureLayerView.filter = {
          // where: `${selectedCategory} = 'Y' ${statement ? ` AND ${statement} = 'Y'` : ""
          //   }`,
          where: `${selectedCategory} = 'Y'`,
          ...distanceFilters,
        };
        if (!isMobile) {
          const panel = document.getElementById("directions-panel");
          if (panel) panel.remove();
          search.clear();
          setTrack(false);
        } else {
          let directions_container: any;
          directions_container = document.getElementById("directions-container");
          directions_container.innerHTML = '';
          const text = document.createElement('P');
          text.className = 'no-directions-available';
          text.innerHTML = 'No directions Available';
          directions_container.appendChild(text);
        }
        if (directionsLayer) {
          directionsLayer.removeAll();
        }
      } else if (featureLayerView) {
        featureLayerView.filter = {
          // where: `${statement ? `${statement}='Y` : ""}`,
          ...distanceFilters,
        };
      }
    }
    if (isCategoryChange && selectedCategory) {
      window.scrollTo(0, 0);
    }
  }
};

const getDistanceFilters = (myLocation: any, distanceFilter: any) => {
  if (myLocation && distanceFilter > 0) {
    const currentLocation = myLocation.geometry;
    return {
      geometry: currentLocation,
      spatialRelationship: "contains",
      distance: distanceFilter,
      units: "kilometers",
    };
  }
  return {};
};

export const getFeatureLayer = async () => {
  let { featureLayerId } = await getFeatureLayerId();
  if (!_servufeatureLayer) {
    const [FeatureLayer] = await loadModules(["esri/layers/FeatureLayer"], {
      css: true,
    });
    _servufeatureLayer = new FeatureLayer({
      portalItem: {
        id: featureLayerId,
      },
      outFields: ["*"],
    });
  }
  return _servufeatureLayer;
}

export const getSearchLocation = async (searchText: String) => {
  const [Search, Locator] = await loadModules(
    ["esri/widgets/Search", "esri/tasks/Locator"],
    { css: true }
  );
  let search = new Search({
    includeDefaultSources: false,
    sources: [
      {
        locator: new Locator({
          url:
            "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
        }),
        singleLineFieldName: "SingleLine",
        countryCode: "AUS",
        name: "Address",
        placeholder: "Search by Address",
      },
    ],
  });

  let searchResult = await search.search(searchText);
  return searchResult?.results[0]?.results[0]?.feature;
}

export const getMapZoom = (distanceFilter: number) => {
  switch (distanceFilter) {
    case 0:
      return 2;
    case 10:
    case 20:
      return 11;
    case 30:
    case 40:
      return 9;
    case 50:
    case 60:
      return 7;
    case 70:
    case 80:
      return 6;
    case 90:
    case 100:
      return 5;
  }
}