import React, { useEffect, useState } from 'react';
import '../../styles/MobileComponents.css';
import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import MobileHeaderComponent from './MobileHeader.component';
import MobileServiceComponent from './MobileServices.component';
//import MobileAboutMe from './MobileAboutMe.component';
import PrivacyPage from '../../pages/Privacy.page';
import DisclaimerPage from '../../pages/Disclaimer.page';
import MobileFeedbackComponent from './MobileFeedback.component';
import { MobileContactUs } from "./MobileContactUs.component"
import ProfileModal from '../ProfileModal.component';
//import GeneralMessage from '../GeneralMessage.component';
import Recommend from '../Recommend.component';
import CookieConsent from "react-cookie-consent";
import Cookies from "universal-cookie";

const MENUS = {
  SERVICES_AND_SUPPORT: 'Services and Support',
  ABOUT_ME: 'About Me',
  FEEDBACK: 'Feedback',
  PRIVACY_POLICY: 'Privacy Policy',
  DISCLAIMER: 'Disclaimer',
}

export const MobileHomeComponent = () => {
  const [title, setTitle] = useState('Services and Support');
  const [menuVisible, setMenuVisible] = useState(false);
  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [searchSelectedItem, setSearchSelectedItem] = useState();
  const [mainLocation, setMainLocation] = useState();
  const [servicesKey, setServicesKey] = useState(0);

  const handleMenuClick = (menu: any) => {
    if (menu === MENUS.SERVICES_AND_SUPPORT && title === menu) {
      setServicesKey(key => ++key);
    }
    setTitle(menu);
    setMenuVisible(false);
  };

  const homeView=()=>{
    setSearchSelectedItem(undefined);
    handleMenuClick(MENUS.SERVICES_AND_SUPPORT)
  }

  useEffect(() => {
    const cookies = new Cookies();
    let isCookieAccepted = cookies.get("CookieConsent");
    console.log({ isCookieAccepted });
    if (isCookieAccepted) {
      setCookieAccepted(true);
    }
  }, []);

  return (
    <div>
      <Sidebar.Pushable className='main-container'>
        <Sidebar
          as={Menu}
          animation='overlay'
          icon='labeled'
          onHide={() => setMenuVisible(false)}
          vertical
          visible={menuVisible}
          width='thin'
        >
          <Menu.Item as='a' onClick={() => homeView()}>
            <Icon name='list' />
          All Services
        </Menu.Item>
         
          <Menu.Item as='a' onClick={() => handleMenuClick(MENUS.FEEDBACK)}>
            <Icon name='comment' />
          Feedback
        </Menu.Item>
          <Menu.Item as='a' onClick={() => handleMenuClick(MENUS.PRIVACY_POLICY)}>
            <Icon name='info' />
          Privacy Policy
        </Menu.Item>
          <Menu.Item as='a' onClick={() => handleMenuClick(MENUS.DISCLAIMER)}>
            <Icon name='help' />
          Disclaimer
        </Menu.Item>
          <Menu.Item style={{ padding: 'unset', bottom: 0, position: 'absolute' }}>
            <MobileContactUs />
          </Menu.Item>
        </Sidebar>
        <Sidebar.Pusher dimmed={menuVisible}>
          <div className='home-menu-drawer-container'>
            <MobileHeaderComponent title={title} setMenuVisible={setMenuVisible} setSearchSelectedItem={setSearchSelectedItem} setMainLocation={setMainLocation} handleMenuClick={handleMenuClick}/>
            {(() => {
              switch (title) {
                case MENUS.SERVICES_AND_SUPPORT:
                  return (
                    <MobileServiceComponent searchSelectedItem={searchSelectedItem} mainLocation={mainLocation} key={servicesKey} />
                  )
                case MENUS.FEEDBACK:
                  return (
                    <MobileFeedbackComponent handleMenuClick={handleMenuClick}  />
                  )
                case MENUS.PRIVACY_POLICY:
                  return (
                    <PrivacyPage handleMenuClick={handleMenuClick}/>
                  )
                case MENUS.DISCLAIMER:
                  return (
                    <DisclaimerPage handleMenuClick={handleMenuClick}/>
                  )
              }
            })()}
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      <CookieConsent
        overlay
        overlayClasses="mobileCookieOverlay"
        location="none"
        cookieName="CookieConsent"
        onAccept={() => setCookieAccepted(true)}
        expires={30}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
      {cookieAccepted && <ProfileModal />}
     
     

    </div>
  );
};
