import { getFeatureLayer } from "./mapHelper";
import { featureLayerIdApi, csvOnlineServicesApi} from "../config.json";

let _csvResults: any;

export const loadRecentItems = async (setVisited: any, servufeatureLayer: any | undefined) => {
    if (!servufeatureLayer) {
        servufeatureLayer = await getFeatureLayer();
    }
    let FilterWithGeometry: any[] = [];
    let recentVisited: any[] = await JSON.parse(
        localStorage.getItem("recentSearches") || "[]"
    );
    const popUpQuery = await servufeatureLayer.createQuery();
    for (let i = 0; i < recentVisited.length; i++) {
        const isOnline = recentVisited[i].Address === "Online";
        if (!isOnline) {
            popUpQuery.where = `PID='${recentVisited[i].PID}'`;
            let result = await servufeatureLayer.queryFeatures(popUpQuery);
            if (result.features.length) {
                let obj = result.features[0].attributes;
                obj.geometry = result.features[0].geometry;
                obj.clickedTime = recentVisited[i].clickedTime;
                FilterWithGeometry.push(obj);
            }
        } else {
            FilterWithGeometry.push(recentVisited[i]);
        }
    }
    setVisited(FilterWithGeometry);
}

export const loadFavourites=async (setFavourites: any, servufeatureLayer: any | undefined)=>{
    if (!servufeatureLayer) {
        servufeatureLayer = await getFeatureLayer();
    }
    let FilterWithGeometry: any[] = [];
    let favourites: any[] = await JSON.parse(
        localStorage.getItem("favourites") || "[]"
    );
    const popUpQuery = await servufeatureLayer.createQuery();
    for (let i = 0; i < favourites.length; i++) {
        const isOnline = favourites[i].Address === "Online";
        if (!isOnline) {
            popUpQuery.where = `PID='${favourites[i].PID}'`;
            let result = await servufeatureLayer.queryFeatures(popUpQuery);
            if (result.features.length) {
                let obj = result.features[0].attributes;
                obj.geometry = result.features[0].geometry;
                FilterWithGeometry.push(obj);
            }
        } else {
            FilterWithGeometry.push(favourites[i]);
        }
    }
    setFavourites(FilterWithGeometry);
}

export const checkFavourite = (PID: any) => {
  let favourites = JSON.parse(localStorage.getItem("favourites") || "[]");
  return favourites.some((element: any) => {
    if (element.PID === PID) {
      return true;
    } else {
      return false;
    }
  });
};

export const setToFavourites=async(item:any)=>{
    let favourites = JSON.parse(localStorage.getItem("favourites") || "[]");
    favourites.push(item);
    localStorage.setItem("favourites", JSON.stringify(favourites));
}

export const removeFromFavourites = async (PID: any) => {
  let favourites = JSON.parse(localStorage.getItem("favourites") || "[]");
  let favouriteItemIndex = favourites.findIndex((element:any)=>element.PID === PID);
  favourites.splice(favouriteItemIndex, 1);
  localStorage.setItem("favourites", JSON.stringify(favourites));
};

export const fetchCookieFilterStatement = async (militaryStatus: any) => {
    if (militaryStatus) {
        switch (militaryStatus) {
            case "serving":
                return "Serving";

            case "former_serving":
                return "Veterans";

            case "family_of_serving":
                return "ServingFam";

            case "family_of_former_serving":
                return "VeteranFam";

            default:
                return undefined;
        }
    }
};

export const getOnlineResults = async ({
    selectedCategory,
    csvResult,
    militaryStatus,
}: any) => {
    // let statement = await fetchCookieFilterStatement(militaryStatus);
    if (selectedCategory) {
        let onlineArray = csvResult.filter(
          (item: any) =>
            // statement
            //     ? item.Address === "Online" &&
            //     item[selectedCategory] === "Y" &&
            //     item[statement] === "Y"
            //     : item.Address === "Online" && item[selectedCategory] === "Y"
            item.Address === "Online" && item[selectedCategory] === "Y"
        );

        onlineArray = onlineArray.sort((a: any, b: any) =>
            a.OrgName.localeCompare(b.OrgName)
        );
        return onlineArray;
    } else {
        return [];
    }
};

export const getOnlineData = async () => {
  if (!_csvResults) {
    _csvResults = [];
    _csvResults = await getOnlineServices();
  }
  return _csvResults;
};

export const getFeatureLayerId = async () => {
  try {
    let FeatureIdResult = await fetch(featureLayerIdApi).then((res) => {
      return res.json();
    });
    return FeatureIdResult;
  } catch (error) {
    return error;
  }
};

export const getOnlineServices = async () => {
  try {
    let onlineServicesResult = await fetch(csvOnlineServicesApi).then((res) => {
      return res.json();
    });
    return onlineServicesResult;
  } catch (error) {
    return error;
  }
};
