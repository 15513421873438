import React, { useState } from "react";
import { Icon, Button } from "semantic-ui-react";
import "../styles/App.css";
import { ServiceItem } from "../containers/ServiceItem";
import { ServiceSearch } from "../containers/ServiceSearch";
import { CategoryResultsView } from "../containers/CategoryResultsView";
import { RecentItemsAndFavouritesView } from "../containers/RecentItemsAndFavouritesView";
import { CategoryListView } from "../containers/CategoryListView";
import { zoomToLayer } from "../actions/mapHelper";

interface ServiceComponentProps {
  getDirections: Function;
  selectedCategory: string | undefined;
  setSelectedCategory: Function;
  onlineResults: any[];
  geoResults: any[];
  selectedItem: any;
  setSelectedItem: Function;
  setHoverItem: Function;
  searchTerm: Function;
  servufeatureLayer: any;
  csvResult: any;
  mapView: any;
  setDistanceFilter: Function
}

export const ServiceComponent = ({
  selectedCategory,
  setSelectedCategory,
  getDirections,
  onlineResults,
  geoResults,
  selectedItem,
  setSelectedItem,
  setHoverItem,
  searchTerm,
  servufeatureLayer,
  csvResult,
  mapView,
  setDistanceFilter
}: ServiceComponentProps) => {
  const [isRecentItemView, setIsRecentItemView] = useState(false);
  const highlightOnHover = (item: any) => {
    if (item.Address !== "Online") setHoverItem(item);
  };

  return (
    <div>
      <div className="search-and-home-wrapper">
       
          <Icon
            className="home"
            basic
            color="blue"
            size='big'
            onClick={() => {
              setSelectedItem(undefined);
              setSelectedCategory(undefined);
              setIsRecentItemView(false);
              zoomToLayer(servufeatureLayer, mapView);
              setDistanceFilter(0);
              mapView.popup.close();
            }
            }
          ></Icon>
       
        <ServiceSearch
          setSelectedItem={setSelectedItem}
          searchTerm={searchTerm}
          csvResult={csvResult}
          servufeatureLayer={servufeatureLayer}
        />
      </div>
      <div style={{ maxHeight: "1000px" }}>
        {(selectedItem || selectedCategory || isRecentItemView) && (
          <Button
            className="go-back-button"
            basic color='blue'
            onClick={() =>
              selectedItem
                ? setSelectedItem(undefined)
                : selectedCategory
                  ? setSelectedCategory(undefined)
                  : isRecentItemView
                    ? setIsRecentItemView(false)
                    : undefined
            }
          >
            <Icon className="long arrow alternate left" />
            Go Back{" "}
          </Button>
        )}
      </div>

      {selectedItem ? (
        <ServiceItem
          orgDetails={selectedItem}
          onDirections={() => getDirections(selectedItem)}
        />
      ) : selectedCategory ? (
        <CategoryResultsView
          onlineResults={onlineResults}
          geoResults={geoResults}
          setSelectedItem={setSelectedItem}
          highlightOnHover={highlightOnHover}
          selectedCategory={selectedCategory}
        />
      ) : isRecentItemView ? (
        <RecentItemsAndFavouritesView
          setSelectedItem={setSelectedItem}
          highlightOnHover={highlightOnHover}
          servufeatureLayer={servufeatureLayer}
        />
      ) : (
        <CategoryListView
          setSelectedCategory={setSelectedCategory}
          setIsRecentItemView={setIsRecentItemView}
        />
      )}
    </div>
  );
};
