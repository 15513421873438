import React, { useState } from 'react'
import {
    Button,
    Rating,
    Form,
    TextArea,
    Radio,
    Message,
  } from "semantic-ui-react";
import { sendFeedback } from "../../actions/searchActions";
import {gaFeedbackSubmitted} from "../../actions/gaevents";

const MobileFeedbackComponent = ({handleMenuClick}:any) => {
    const [submitted, isSubmitted] = useState(false);
    const [rating, setRating] = useState(8);
    const [feedback, setFeedback] = useState("");
    const [position,setPosition] = useState<any>("I am a Veteran/Family Member");

    const submitFeedback = () => {
        let historyArray: any[] = JSON.parse(
          localStorage.getItem("recentSearches") || "[]"
        );
        let visitedPlaces: any[] = historyArray
          .sort((a, b) => (a.clickedTime > b.clickedTime ? -1 : 1))
          .map((item) => item.OrgName);
          
        if (position.includes("Provider"))  
        {
          gaFeedbackSubmitted(rating,feedback.length,true);
        }
        else
        {
          gaFeedbackSubmitted(rating,feedback.length,false);
        }
          
        let  arrayoflines :  any[] = feedback.split('\n');  
        //console.log({ rating, position,feedback, visitedPlaces });
        sendFeedback(`${rating}/10`, position,arrayoflines, visitedPlaces);
        isSubmitted(true);
    };

  const onClose = () => {
    setRating(0);
    isSubmitted(false);
    setPosition("I am a Veteran/Family Member");
    handleMenuClick("Services and Support");
  };

  return (
    <div className="body-container" style={{ padding: 10 }}>
      {!submitted && (
        <div>
          <div>
            <div>
              <h3>How would you rate your experience?</h3>
              <div className="numberSlider">1 10</div>
              <input
                type="range"
                min={1}
                max={10}
                value={rating}
                onChange={(e: any) => setRating(e.target.value)}
                className="ratingSlider"
              />
              <br />
              <Rating rating={rating} maxRating={10} size="huge" />
            </div>
        <div style={{ marginTop: 20 }}>
            <h3>Do you have any additional comments?</h3>
              <h5>(If you wish to you may leave an email address within your feedback comments)</h5>
            <Form>
            <TextArea
                rows={6}
                maxLength="1380"
                onChange={(e: any) => {
                    setFeedback(e.target.value);
                }}
            />
            </Form>
            <Form style={{ marginTop: 20 }}>
            <Form.Field>
                <Radio
                label="I am a Service Provider"
                name="radioGroup"
                value="I am a Service Provider"
                checked={position === "I am a Service Provider"}
                onChange={(e: any, { value }) => setPosition(value)}
                />
            </Form.Field>
            <Form.Field>
                <Radio
                label="I am a Veteran/Family Member"
                name="radioGroup"
                value="I am a Veteran/Family Member"
                checked={position === "I am a Veteran/Family Member"}
                onChange={(e: any, { value }) => setPosition(value)}
                />
            </Form.Field>
            </Form>
        </div>
          </div>
        </div>
      )}
      {submitted && (
        <div>
          <Message positive>
            <Message.Header>Thank you for your feedback</Message.Header>
          </Message>
        </div>
      )}

      <Button
        onClick={submitted ? onClose : submitFeedback}
        positive
        className={submitted ? "ui right floated button" : "ui left floated button"}
      >
        {submitted ? "Close" : "Send"}
      </Button>

      <Button
        onClick={() =>handleMenuClick("Services and Support")}
        positive
        className={"ui right floated button"}
      >
        {"Go Back"}
      </Button>
    </div>
  );
}

export default MobileFeedbackComponent;