import React, { useState, useEffect } from "react";
import { Button, Card, Icon, Popup } from "semantic-ui-react";
import Mobile, { isMobile } from 'is-mobile';
import { checkFavourite, setToFavourites, removeFromFavourites } from "../actions/dataHelper";
import {gaFavouritesClicked, gaWebsiteClicked} from '../actions/gaevents';

interface ServiceItemProps {
  orgDetails: any;
  onDirections: any;
}

export const ServiceItem = ({ orgDetails, onDirections }: ServiceItemProps) => {
  const [isFavourite, setIsFavourite] = useState(false);
  const {
    PID,
    OrgName: orgName,
    Address: address,
    OpenHours: openHours,
    Website: website,
    Specialises : Specialises,
    Phone : Phone,
    PhonePretty : PhonePretty
  } = orgDetails;
  const isOnline = address === "Online";

  useEffect(() => {
    setIsFavourite(checkFavourite(PID));
  }, [PID]);

  const addToFavourites = () => {
    setToFavourites(orgDetails);
    setIsFavourite(true);
    gaFavouritesClicked(orgName,true);
  }

  const removeFavourite = () => {
    removeFromFavourites(PID);
    setIsFavourite(false);
    gaFavouritesClicked(orgName,false);
  }
  
   const WebsiteClickedNotOnline = () => {
   // removeFromFavourites(PID);
  //  setIsFavourite(false);
  
   window.open( `${!website.startsWith("http") ? "https://" : ""}${website}`)
   console.log("notonline");
  
  gaWebsiteClicked(true, orgName);
  }
  
  const phoner = 'tel:' + Phone  ;

  return (
    <div className="service-content">
      <Card style={{ width: "100%", ...(isMobile() ? { marginTop: 0, ...(!isOnline ? { border: 'unset', boxShadow: 'unset' } : {}) } : { marginTop: 40 }) }}>
        <Card.Content style={Mobile() ? { paddingTop: 10 } : {}}>
          {isFavourite ? (
            <Popup
              content="Remove From Favourites"
              trigger={<Icon className="star" onClick={removeFavourite} />}
              position="bottom right"
            />
          ) : (
            <Popup
              content="Add To Favourites"
              trigger={
                <Icon className="star outline" onClick={addToFavourites} />
              }
              position="bottom right"
            />
          )}
          <Card.Header>
            {!isOnline && <Icon className="map marker alternate" />}
            <div>
              {orgName}
              {!isOnline && <Card.Description>{address}</Card.Description>}
              <Card.Description>{openHours}</Card.Description>
            </div>
          </Card.Header>
        </Card.Content>
        {!isOnline && (
          <Card.Content style={Mobile() ? { marginLeft: 47, paddingTop: 0, paddingBottom: 0 } : { marginLeft: 45 }}>
            <div className="websiteLink" style={{ marginBottom: 10 }}>
              {website && (
                <Button
                  className="ui primary button"
                  target="_blank"
                  onClick={WebsiteClickedNotOnline}
                >
                  Website
                </Button>
              )}
              <Button className="ui primary button" onClick={onDirections}>
                Directions
              </Button>
            </div>
          </Card.Content>
        )}
        {isOnline && (
          <Card.Content style={{ paddingTop: 8 }}>
            <div className="websiteLink" style={{ marginBottom: 0 }}>
              <a
                href={`${!website.startsWith("http") ? "https://" : ""
                  }${website}`}
                rel="noopener noreferrer"
                target="_blank"
                style={{ opacity: 1 }}
                className="link"
              >
                {website}
              </a>
            </div>
            <br/><br/> 
            Phone : <a href= {phoner}>{PhonePretty}</a>
            <br></br>
            <br></br>
            Specialises in : <br></br>
            {Specialises}
          </Card.Content>
        )}
      </Card>
    </div >
  );
};
