import React, { useEffect, useState } from 'react';
import { Sidebar, Segment, Menu, Modal } from 'semantic-ui-react';
import MobileMapComponent from './MobileMap.component';
import { SearchModal } from '../SearchModal.component';
import MobileRecentComponent from './MobileRecent.component';
import { CategoryListViewMobile } from '../../containers/mobile/CategoryListViewMobile';
import categories from "../../configs/categories.json";

const MobileServiceComponent = ({ searchSelectedItem, mainLocation }: any) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [recentVisible, setRecentVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [onlineSelected, setOnlineSelected] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [inPersonClicked, setInPersonClicked] = useState(false);
  const [myLocation, setMyLocation] = useState<any>();
  const [selectedItem, _setSelectedItem] = useState();
  const selectedItemRef = React.useRef(selectedItem);
  const setSelectedItem = (data: any) => {
    selectedItemRef.current = data;
    _setSelectedItem(data);
  };

  useEffect(() => {
    if (searchSelectedItem) {
      setSelectedItem(searchSelectedItem);
    }
  }, [searchSelectedItem]);

  useEffect(() => {
    if (mainLocation) {
      setMyLocation(mainLocation);
    }
  }, [mainLocation]);

  const renderServiceResult = () => {
    return (
      <Sidebar.Pushable as={Segment} className='service-result-container' style={showMap || selectedItem ? {} : { display: 'none' }}>
        <Sidebar.Pusher dimmed={visible}>
          <div className='back-header-container'>
            <i className='arrow left icon mobile' onClick={handleBackClick} />
            {!recentVisible && <h4 className='ui center aligned header heading'>{categories.find((i) => i.category === selectedCategory)?.displayName || ""}</h4>}
          </div>
          <MobileMapComponent
            selectedCategory={selectedCategory}
            searchText={searchText}
            setOpenSearch={setOpenSearch}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            selectedItemRef={selectedItemRef}
            recentVisible={recentVisible}
            onlineSelected={onlineSelected}
            myLocation={myLocation}
            setMyLocation={setMyLocation}
            inPersonClicked={inPersonClicked} />
          <SearchModal openSearch={openSearch} setOpenSearch={setOpenSearch} searchTerm={searchTerm} setTrack={()=>console.log('setTrack')} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }

  const renderSearchSelection = () => {
    return (
      <Modal
        onClose={() => setVisible(false)}
        open={visible}
      >
        <Menu className="search-selection-menu" fluid vertical>
          <Menu.Item onClick={handleInPersonClick}>In-Person</Menu.Item>
          <Menu.Item onClick={handleOnlineClick}>Online</Menu.Item>
        </Menu>
      </Modal>
    );
  }

  const handleInPersonClick = () => {
      setVisible(false);
      setInPersonClicked(true);
      if(searchText) {
        setShowMap(true);
      } else setOpenSearch(true);
  }

  const handleOnlineClick = () => {
    setVisible(false);
    setOnlineSelected(true);
    setShowMap(true);
  }

  const serviceClick = (service: string) => {
    setSelectedCategory(service);
    setVisible(true);
  }

  const handleBackClick = () => {
    setSelectedCategory('');
    setOnlineSelected(false);
    setSelectedItem(undefined);
    setInPersonClicked(false);
    setShowMap(false);
  }

  const searchTerm = (searchText: any) => {
    setSearchText(searchText);
    setShowMap(true);
  }

  return (
    <div>
      {renderSearchSelection()}
      {renderServiceResult()}
      {!showMap && !selectedItem &&
        <React.Fragment>
          {recentVisible
            ? <MobileRecentComponent
              setRecentVisible={setRecentVisible}
              setSelectedItem={setSelectedItem} />
            : <CategoryListViewMobile serviceClick={serviceClick} setIsRecentItemView={setRecentVisible} />}
        </React.Fragment>
      }
    </div>
  );
};

export default MobileServiceComponent;
